import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill, BsArrowsMove, BsFillCaretUpFill, BsFillCaretDownFill, BsSortAlphaDown, BsSortAlphaUp } from 'react-icons/bs';
import Dropzone from 'react-dropzone';
import { BsUpload, BsFillInfoCircleFill } from 'react-icons/bs';

import LoaderComponent from '../LoaderComponent';
import { saveClientConfigure, fetchConfigureQuestions, DownloadToolConfiguration, UploadToolConfiguration, uploadMLModelFiles, GetDependentAnswersTemplate, UploadConfiguredDependentAnswers, DownloadConfiguredDependentAnswers } from '../../api/api';
import Preview from '../Preview_Questions';
import QuestionsConfig from './ConfigureQuestions.config.json';
import { MapFamilyQuestion } from './MapFamilyQuestion';
import DropdownComponent from './DropdownComponent';
import MultipleFlagSelect from './MultipleFlagSelect';
import { UploadOrDownloadBulkList } from './UploadOrDownloadBulkList';
import { UploadDependentAnswers } from './UploadDependentAnswers';
import SubHeader from './SubHeader';
import { flagList } from './MultipleFlagData';

import './QuestionsConfigure.css';

const animatedComponents = makeAnimated();
const customStyles = {
    content: {
        position: 'absolute',
        width: '70%',
        top: '25%',
        bottom: '10%',
        marginTop: '-150px',
        left: '20%',
        right: '10%',
        marginLeft: '-100px',
        height: 'auto',
        overflowY: 'scroll',
        border: '2px solid #007bff'
    }
};

const colourStyles = {
    option: (provided, state) => {
        const {
            data: { isOutcomesQues },
            isSelected
        } = state;
        return { ...provided, color: isSelected ? 'yellow' : isOutcomesQues ? '#FF8B00' : 'black', backgroundColor: isSelected ? 'green' : 'white', fontWeight: isOutcomesQues ? 'bold' : '', boxShadow: '0px 2px 5px #888888' };
    },
    multiValue: (provided, state) => {
        const {
            data: { isOutcomesQues }
        } = state;
        return {
            ...provided,
            backgroundColor: isOutcomesQues ? '#FF8B00' : '#E9ECEF',
            color: isOutcomesQues ? '#fff' : 'black'
        };
    },
    multiValueLabel: (provided, state) => {
        const {
            data: { isOutcomesQues }
        } = state;
        return {
            ...provided,
            color: isOutcomesQues ? '#fff' : 'black'
        };
    },
    multiValueRemove: (provided) => {
        return {
            ...provided,
            cursor: 'pointer'
        };
    },
    control: (provided) => ({
        ...provided,
        marginTop: '5%'
    })
};

const ReferralStatusMappedList = QuestionsConfig.LastQuestions[0].suggested_answers;

class QuestionConfigure extends React.Component {
    constructor() {
        super();
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            sections: [
                {
                    section: 'Demographics',
                    section_id: 0,
                    related: 'false',
                    is_odm: 'no',
                    questions: QuestionsConfig.Questions
                }
            ],
            lastItemId: 3,
            lastSectionId: 0,
            show: {},
            showEffect: {},
            expandAll: false,
            Org_id: '',
            org_type: '',
            readOnly: '',
            is_historic_data_available: '',
            configured_sections: [],
            isPreview: false,
            isOpen: false,
            logoPath: '',
            header_color: '',
            hasError: false,
            err_msg: [],
            isLoading: false,
            relatedSections: [],
            relatedQuestions: [],
            outcomesRelatedQues: [],
            fmlBasicRequirmentList: [],
            is_configuration_available: false,
            set_unique_id: false,
            set_searchble_item: false,
            answerModal: {
                isOpen: false,
                sectionIndex: '',
                questionIndex: '',
                question: '',
                answerIndex: ''
            }
        };
    }
    handleNavToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };
    handleNavToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    };
    handleScroll = () => {
        let mybuttonTop = document.getElementById('btn-back-to-top');
        let mybuttonBottom = document.getElementById('btn-back-to-bottom');

        if (mybuttonTop || mybuttonBottom) {
            if (document.body.scrollTop > 99 || document.documentElement.scrollTop > 99) {
                mybuttonTop.style.display = 'block';
            } else {
                mybuttonTop.style.display = 'none';
            }
            if (document.documentElement.scrollHeight - 1033 < document.documentElement.scrollTop) {
                mybuttonBottom.style.display = 'none';
            } else {
                mybuttonBottom.style.display = 'block';
            }
        }
    };
    // Find - Outcome Section all question, which is Related : 'yes'
    findOutcomesRelatedQues = () => {
        let { Config } = this.props.match.params;
        if (Config === '1') {
            let { sections } = this.state;
            let outcomesRelatedQues = [];
             sections.find((sec) => { 
                    if(sec.section === 'Outcomes'){
                        sec.questions.filter((ques) => {
                            if(ques.related === 'yes'){
                             outcomesRelatedQues.push(ques.question)
                            }});
                     } 
                    }
            
            )
            this.setState((prevState) => ({ ...prevState, outcomesRelatedQues: [...outcomesRelatedQues] }));
        }
    };

    componentDidMount = async () => {
        let { id, Config } = this.props.match.params;
        let lastSection = {
            section: 'Outcomes',
            section_id: this.state.sections.length,
            related: 'false',
            is_odm: 'no',
            questions: QuestionsConfig.LastQuestions
        };
        let fosterLastSection = {
            section: 'Outcomes',
            section_id: this.state.sections.length,
            related: 'false',
            is_odm: 'no',
            questions: QuestionsConfig.FosterCareLastQuestions
        };
        let countyDemographics = QuestionsConfig.CountyDemographics;
        let fosterCareDemographics = QuestionsConfig.FosterCareDemographics;
        let fosterCareFamilyDemographics = QuestionsConfig.FosterCareFamilyDemographics;
        window.addEventListener('scroll', this.handleScroll);
        const { org_type, logo_path: logopath, header_color: headerColor } = this.props.organizationsList.find((org) => org.id === Number(id));
        let response = await fetchConfigureQuestions(id, Config);
        let FmlBasicRequirment = await MapFamilyQuestion(org_type, id, Config, this.state.fmlBasicRequirmentList);

        // 1. When organization have not sections.
        if (!Array.isArray(response.response)) {
            if (org_type) {
                switch (org_type) {
                    case 1:
                    case 4:
                        this.setState((prevState) => ({
                            ...prevState,
                            sections: [...prevState.sections, lastSection]
                        }));
                        break;
                    case 2:
                        this.setState((prevState) => ({
                            ...prevState,
                            sections: [countyDemographics, lastSection]
                        }));
                        break;
                    case 3:
                        let firstSection = Config == 1 ? fosterCareDemographics : fosterCareFamilyDemographics;
                        this.setState((prevState) => ({
                            ...prevState,
                            sections: Config == 2 ? [firstSection] : [firstSection, fosterLastSection]
                        }));
                        break;
                    default:
                        break;
                }
            }
        } else {
            // 2. When organization have sections. and configuration uploaded from json
            // let isOutcomes = response.response.some((section) => (section.section === 'Outcomes' ? true : false));
            // !isOutcomes && Config !== '2' && response.response.push(lastSection);

            let sectionResponse = Array.isArray(response.response) && response.response.find((section) => section.section === 'Outcomes');
            let index = Array.isArray(response.response) && response.response.findIndex((section) => section.section === 'Outcomes');

            if (sectionResponse) {
                if (index >= 0) {
                    response.response.splice(index, 1);
                    response.response.push(sectionResponse);
                }
            }
        }
        let sections = response.response ? [...response.response] : [];

        const section_mapped = (suggested_answers, suggested_jump) => {
            let arrIndex = [];
            let newSuggestedJumps = [];
            if (suggested_jump.length === 0) {
                suggested_answers.map((item) =>
                    suggested_jump.push({
                        answer: item?.value,
                        jumpto: [],
                        question_jumpto: [],
                        mapping_answers: [],
                        is_deleted: item?.is_deleted
                    })
                );
            }
            suggested_jump.filter((o1) => {
                return suggested_answers.some((o2) => {
                    if (o1?.answer === o2.value) {
                        let index = suggested_answers.indexOf(o2);
                        return arrIndex.push(index);
                    }
                });
            });

            suggested_answers.forEach((item) => {
                if (arrIndex.length > 0) {
                    newSuggestedJumps.push({ answer: item?.value, jumpto: [], question_jumpto: [], mapping_answers: [], is_deleted: item?.is_deleted });
                }
            });
            suggested_jump.forEach((obj, i) => {
                arrIndex.forEach((a, j) => {
                    if (i === j) {
                        return newSuggestedJumps.splice(a, 1, obj);
                    }
                });
            });
            newSuggestedJumps = newSuggestedJumps.map((item) => {
                return {
                    ...item,
                    is_deleted: item.is_deleted ? item.is_deleted : false,
                    mapping_answers: item.mapping_answers ? item.mapping_answers : []
                };
            });
            return newSuggestedJumps;
        };

        // Adding defualt empty jumto & question_jumpto to suggested answers & default add_type
        sections = sections.map((sec) => {
            return {
                ...sec,
                questions: sec.questions.map((ques) => {
                    return {
                        ...ques,
                        add_type: 'normal',
                        suggested_answers: ques.suggested_answers.length > 0 ? ques.suggested_answers.map((item) => (item.hasOwnProperty('is_deleted') ? item : Object.assign(item, { is_deleted: false }))) : []
                    };
                })
            };
        });

        sections = sections.map((sec) => {
            return {
                ...sec,
                questions: sec.questions.map((ques, index) => {
                    return {
                        ...ques,
                        question_id: index,
                        suggested_jump: section_mapped(ques.suggested_answers, ques.suggested_jump),
                        suggested_answers: ques.question === 'Referral Status' ? ques.suggested_answers.map((ans) => (ans.value === 'Pending' ? { ...ans, isChecked: true } : ans)) : ques.suggested_answers
                    };
                })
            };
        });

        if (response.message !== 'sections not available') {
            this.setState(
                {
                    Org_id: id,
                    org_type: org_type,
                    sections: Array.isArray(response.response) ? sections : this.state.sections,
                    configured_sections: Array.isArray(response.response) ? response.response : [],
                    readOnly: response.is_prediction_available || response.is_historic_data_available,
                    is_historic_data_available: response.is_historic_data_available,
                    logoPath: logopath,
                    header_color: headerColor,
                    fmlBasicRequirmentList: FmlBasicRequirment,
                    is_configuration_available: true
                },
                () => {
                    this.UniqueIdSearchFieldService();
                }
            );
        } else {
            this.setState({
                Org_id: id,
                org_type: org_type,
                logoPath: logopath,
                header_color: headerColor,
                fmlBasicRequirmentList: FmlBasicRequirment,
                is_configuration_available: false
            });
        }
        this.findOutcomesRelatedQues();
    };

    handleMove = async (e) => {
        e.preventDefault();
        const { srcI, desI, list } = this.state;
        if (desI) {
            // const src = list[srcI].id;
            // const des = list[desI].id;
            list.splice(desI, 0, list.splice(srcI, 1)[0]);
            await this.saveList(list);
            //    const response = await alterQuestions(src, des)
        }
        this.setState({
            showPOPUP: false,
            btnAction: ''
        });
    };

    getList() {
        return (localStorage.getItem('theList') && JSON.parse(localStorage.getItem('theList'))) || this.list;
    }
    saveList = (list) => {
        localStorage.setItem('theList', JSON.stringify(list));
    };
    handleDelete = (idx, idy, data) => (e) => {
        const index = e.target.dataset.id;
        const secid = e.target.dataset.secid;

        let sections = [...this.state.sections];
        if (data.id !== '' && data.value !== '') {
            let result = sections[secid].questions[idx].suggested_answers.find((item) => item.id === data.id && item.value === data.value);
            if (result) {
                result.is_deleted = true;
            }
            let jumpResult = sections[secid].questions[idx].suggested_jump.find((item) => item.answer === data.value);
            if (jumpResult) {
                jumpResult.is_deleted = true;
                jumpResult.mapping_answers = [];
            }
        } else {
            sections[secid].questions[idx].suggested_answers = sections[secid].questions[idx].suggested_answers.length > 0 ? sections[secid].questions[idx].suggested_answers.filter((item, index) => index !== idy) : [];
            sections[secid].questions[idx].suggested_jump = sections[secid].questions[idx].suggested_jump.length > 0 ? sections[secid].questions[idx].suggested_jump.filter((item, index) => index !== idy) : [];
        }
        this.setState({
            sections
        });
    };

    alterSectionId = () => {
        let sections = [...this.state.sections];
        sections = sections.map((item, index) => {
            return {
                ...item,
                section_id: index
            }
        })
        this.setState({ sections });
    }

    alterID = (id) => {
        let sections = [...this.state.sections];
        sections[id].questions = sections[id].questions.map((item, index) => {
            return {
                ...item,
                question_id: index
            };
        });
        this.setState({ sections });
    };

    quesDelete = (ques_id) => (e) => {
        e.preventDefault();
        const idi = e.target.dataset.id;
        let sections = [...this.state.sections];
        // sections[idi].questions = [...sections[idi].questions.slice(0, i), 
        //                            ...sections[idi].questions.slice(i + 1)];
        sections = sections.map((sec) => {
            if (sec.section === sections[idi].section) {
                return {
                    ...sec,
                    questions: sec.questions.filter((ques) => {
                        if (ques.question_id !== ques_id) {
                            return {
                                ques
                            };
                        }

                    })
                };
            } else {
                return sec;
            }
        });
        let id = sections[idi].questions.length;

        this.setState({
            sections,
            lastItemId: id
        }, () => this.alterID(idi));
    };

    sectionDelete = (i) => (e) => {
        e.preventDefault();
        let sections = [...this.state.sections];
        sections = [...sections.slice(0, i), ...sections.slice(i + 1)];

        this.setState({
            sections
        }, () => this.alterSectionId());
        this.setState((prevState) => ({
            ...prevState,
            lastSectionId: prevState.lastSectionId - 1
        }));
    };

    addAnswer = (i, index) => (e) => {
        e.preventDefault();
        let secid = e.target.dataset.secid;
        let answer_type = e.target.dataset.answer;
        let sections = [...this.state.sections];
        let id = this.state.sections[secid].questions[i].suggested_answers.length;

        sections[secid].questions[i].suggested_answers = this.state.sections[secid].questions[i].suggested_answers.concat(answer_type === 'CHECKBOX' ? [{ id: id, value: '', isChecked: false, is_deleted: false, setType: 'setA' }] : [{ id: id, isChecked: false, is_deleted: false, value: '' }]);
        sections[secid].questions[i].suggested_jump = this.state.sections[secid].questions[i].suggested_jump.concat([{ answer: '', jumpto: [], question_jumpto: [], mapping_answers: [], is_deleted: false }]);
        this.setState({ sections });
    };

    addReferralAnswer = (secid, quesid) => {
        let sections = [...this.state.sections];
        let length = sections[secid].questions[quesid].suggested_answers.length;
        let jumpTo = [];
        let suggestedId = sections[secid].questions[quesid].suggested_answers.map((item) => item.id);

        let list = [];
        if (length !== ReferralStatusMappedList.length) {
            for (let j = 0; j <= ReferralStatusMappedList.length - 1; j++) {
                if (suggestedId?.includes(ReferralStatusMappedList[j].id)) {
                    let record = sections[secid].questions[quesid].suggested_answers.find((item) => item.id === ReferralStatusMappedList[j].id);
                    let record1 = sections[secid].questions[quesid].suggested_jump.find((item, index) => record.mappingList?.includes(item.answer));

                    list.push(record);
                    jumpTo.push(record1);
                } else {
                    list.push(ReferralStatusMappedList[j]);

                    jumpTo.push({
                        answer: ReferralStatusMappedList[j].value,
                        jumpto: [],
                        question_jumpto: [],
                        is_deleted: false,
                        mapping_answers: []
                    });
                }
            }
            sections[secid].questions[quesid].suggested_answers = list;
            sections[secid].questions[quesid].suggested_jump = jumpTo;
            this.setState({ sections });
        }
    };

    //Any Section is_odm : "no" then 1. From current section -> from jumpto remove outcome, 2. From current section -> from question jump remove all outcome related : "yes" question
    removeAllOdm = (sections, secname, outcomesRelatedQues) => {
        sections = sections.map((sec) => {
            if (sec.section === secname) {
                return {
                    ...sec,
                    questions: sec.questions.map((ques) => {
                        return {
                            ...ques,
                            is_odm: 'no',
                            suggested_jump:
                                Array.isArray(ques.suggested_jump) && ques.suggested_jump.length > 0
                                    ? ques.suggested_jump.map((s, i) => {
                                        return {
                                            ...s,
                                            jumpto: Array.isArray(s?.jumpto) && s?.jumpto.length > 0 ? s?.jumpto.filter((j) => j !== 'Outcomes') : [],
                                            question_jumpto: Array.isArray(s?.question_jumpto) && s?.question_jumpto.length > 0 ? s.question_jumpto.filter((k) => (outcomesRelatedQues.includes(k) === true ? null : k)) : []
                                        };
                                    })
                                    : []
                        };
                    })
                };
            } else {
                return sec;
            }
        });

        return sections;
    };
    //Any Question is_odm : "no" then 1. From current question -> Remove all outcome question
    removeQuestionOdm = (sections, secid, question, outcomesRelatedQues) => {
        let updatedSection = [];
        updatedSection = sections.map((sec, index) => {
            if (index === Number(secid)) {
                return {
                    ...sec,
                    questions: sec.questions.map((ques) => {
                        if (ques.question === question) {
                            return {
                                ...ques,
                                is_odm: 'no',
                                suggested_jump:
                                    Array.isArray(ques.suggested_jump) && ques.suggested_jump.length > 0
                                        ? ques.suggested_jump.map((s, i) => {
                                            return {
                                                ...s,
                                                jumpto: Array.isArray(s?.jumpto) && s?.jumpto.length > 0 ? s?.jumpto.filter((j) => j !== 'Outcomes') : [],
                                                question_jumpto: Array.isArray(s?.question_jumpto) && s?.question_jumpto.length > 0 ? s.question_jumpto.filter((k) => (outcomesRelatedQues.includes(k) === true ? null : k)) : []
                                            };
                                        })
                                        : []
                            };
                        } else {
                            return ques;
                        }
                    })
                };
            } else {
                return sec;
            }
        });
        return updatedSection;
    };

    UpdateDuplicateItems = (data, value, id) => {
        if (id && id !== '') {
            let listOfIds = data.map((item) => item.id);
            let highestId = Math.max(...listOfIds);
            const duplicateList = listOfIds.filter((item, index) => {
                return listOfIds.indexOf(item) !== index;
            });
            if (duplicateList.includes(id)) {
                return highestId + 1;
            } else {
                return id;
            }
        } else {
            return id;
        }
    };

    handleChange = (e) => {
        let secid = e.target.dataset.secid;
        let secname = e.target.dataset.secname;
        let id = e.target.dataset.id;
        if (QuestionsConfig.ItemValuesList?.includes(e.target.dataset.name)) {
            if (['suggested_answers', 'suggested_jump', 'setType', 'child_questions'].includes(e.target.dataset.name)) {
                let sections = [...this.state.sections];
                if (e.target.dataset.name === 'suggested_jump') {
                    sections[secid].questions[e.target.dataset.id].suggested_jump[e.target.dataset.idy] = {
                        answer: sections[secid].questions[e.target.dataset.id].suggested_answers[e.target.dataset.idy].value,
                        jumpto: e.target.value
                    };
                } else if (e.target.dataset.name === 'setType' && e.target.name === `set_type-${id}${e.target.dataset.idy}`) {
                    sections[secid].questions[e.target.dataset.id].suggested_answers[e.target.dataset.idy] = {
                        ...sections[secid].questions[e.target.dataset.id].suggested_answers[e.target.dataset.idy],
                        setType: e.target.value
                    };
                } else {
                    sections[secid].questions[e.target.dataset.id].suggested_answers[e.target.dataset.idy] = {
                        id: this.UpdateDuplicateItems(sections[secid].questions[e.target.dataset.id].suggested_answers, e.target.value, parseInt(sections[secid]?.questions[e.target.dataset.id]?.suggested_answers[e.target.dataset.idy].id)),
                        value: e.target.value,
                        isChecked: false,
                        is_deleted: sections[secid]?.questions[e.target.dataset.id]?.suggested_answers[e.target.dataset.idy]?.is_deleted,
                        ...(sections[secid].questions[e.target.dataset.id].answer_type === 'CHECKBOX' && { setType: sections[secid].questions[e.target.dataset.id].suggested_answers[e.target.dataset.idy].setType ? sections[secid].questions[e.target.dataset.id].suggested_answers[e.target.dataset.idy].setType : 'setA' })
                    };
                    let dataSet = sections[secid].questions[e.target.dataset.id].suggested_jump[e.target.dataset.idy];
                    sections[secid].questions[e.target.dataset.id].suggested_jump[e.target.dataset.idy] = {
                        answer: e.target.value,
                        jumpto: dataSet.jumpto ? dataSet.jumpto : [],
                        question_jumpto: dataSet.question_jumpto ? dataSet.question_jumpto : [],
                        mapping_answers: [],
                        is_deleted: sections[secid]?.questions[e.target.dataset.id]?.suggested_answers[e.target.dataset.idy]?.is_deleted
                    };
                }

                this.setState({ sections });
            } else {
                let sections = [...this.state.sections];
                let configured_sections = [...this.state.configured_sections];
                switch (e.target.dataset.name) {
                    case 'answer_type':
                        sections[secid].questions[e.target.dataset.id][e.target.dataset.name] = e.target.value;
                        sections[secid].questions[e.target.dataset.id]['re_type'] = e.target.value == 'TEXT' ? 'no' : '';
                        sections[secid].questions[e.target.dataset.id]['is_to_be_mask'] = ['TEXT', 'NUMBER'].includes(e.target.value) ? 'no' : '';
                        sections[secid].questions[e.target.dataset.id]['is_odm'] = ['RADIO', 'SELECT'].includes(e.target.value) ? sections[secid].questions[e.target.dataset.id]['is_odm'] : 'no';
                        sections[secid].questions[e.target.dataset.id].suggested_answers =
                            sections[secid].questions[e.target.dataset.id].suggested_answers.length === 0
                                ? ['RADIO', 'SELECT', 'CHECKBOX'].includes(e.target.value)
                                    ? [
                                        {
                                            id: 0,
                                            value: '',
                                            isChecked: false,
                                            is_deleted: false
                                        }
                                    ]
                                    : []
                                : sections[secid].questions[e.target.dataset.id].suggested_answers;
                        sections[secid].questions[e.target.dataset.id].suggested_jump =
                            sections[secid].questions[e.target.dataset.id].suggested_jump.length === 0
                                ? ['RADIO', 'SELECT', 'CHECKBOX'].includes(e.target.value)
                                    ? [
                                        {
                                            answer: '',
                                            jumpto: [],
                                            question_jumpto: [],
                                            mapping_answers: [],
                                            is_deleted: false
                                        }
                                    ]
                                    : []
                                : sections[secid].questions[e.target.dataset.id].suggested_jump;

                        //Remove all setType keys from suggested answer when answer type is not CHECKBOX
                        e.target.value != 'CHECKBOX' &&
                            sections[secid].questions[e.target.dataset.id].suggested_answers &&
                            sections[secid].questions[e.target.dataset.id].suggested_answers.map((answer) => {
                                delete answer.setType;
                            });
                        this.setState({ sections });
                        break;
                    case 'question':
                        e.target.value.includes('.')
                            ? this.setState({
                                hasError: true,
                                err_msg: `Section ${parseInt(secid) + 1} :- Question ${parseInt(e.target.dataset.id) + 1} : ${e.target.value} should not contains special characters`
                            })
                            : (sections[secid].questions[e.target.dataset.id][e.target.dataset.name] = e.target.value);
                        this.setState({ sections, hasError: false, err_msg: [] });
                        break;
                    case 'validation2':
                        if (['Numbers', 'Alphabet'].includes(sections[secid].questions[e.target.dataset.id].validation2)) {
                            sections[secid].questions[e.target.dataset.id].spl_characters = '';
                        }

                        if (e.target.value === '') {
                            sections[secid].questions[e.target.dataset.id].error_msg = '';
                        }
                        sections[secid].questions[e.target.dataset.id][e.target.dataset.name] = e.target.value;
                        this.setState({ sections });
                        break;
                    case 'validation3':
                        if (e.target.value === '' && sections[secid].questions[e.target.dataset.id].validation4 === '') {
                            sections[secid].questions[e.target.dataset.id].error_msg2 = '';
                        }

                        if (configured_sections[secid]?.questions[e.target.dataset.id]) {
                            if (configured_sections[secid].questions[e.target.dataset.id][e.target.dataset.name] !== '' && e.target.value > configured_sections[secid].questions[e.target.dataset.id][e.target.dataset.name]) {
                                this.state.is_historic_data_available && alert('Minimum value can not be changed once organization have historic data.');
                                sections[secid].questions[e.target.dataset.id][e.target.dataset.name] = configured_sections[secid].questions[e.target.dataset.id][e.target.dataset.name];
                            } else {
                                sections[secid].questions[e.target.dataset.id][e.target.dataset.name] = e.target.value;
                            }
                        } else {
                            sections[secid].questions[e.target.dataset.id][e.target.dataset.name] = e.target.value;
                        }
                        this.setState({ sections });
                        break;
                    case 'validation4':
                        if (e.target.value === '' && sections[secid].questions[e.target.dataset.id].validation3 === '') {
                            sections[secid].questions[e.target.dataset.id].error_msg2 = '';
                        }
                        if (configured_sections[secid]?.questions[e.target.dataset.id]) {
                            if (configured_sections[secid].questions[e.target.dataset.id][e.target.dataset.name] !== '' && e.target.value < configured_sections[secid].questions[e.target.dataset.id][e.target.dataset.name]) {
                                this.state.is_historic_data_available && alert('Maximum value can not be changed once organization have historic data.');
                            } else {
                                sections[secid].questions[e.target.dataset.id][e.target.dataset.name] = e.target.value;
                            }
                        } else {
                            sections[secid].questions[e.target.dataset.id][e.target.dataset.name] = e.target.value;
                        }
                        this.setState({ sections });
                        break;
                    case 'exact_match':
                        sections[secid].questions[e.target.dataset.id][e.target.dataset.name] = e.target.checked;
                        this.setState({ sections });
                        break;
                    case 'is_primary_key':
                        sections[secid].questions[e.target.dataset.id][e.target.dataset.name] = e.target.checked;
                        this.setState({ sections }, () => {
                            this.UniqueIdSearchFieldService();
                        });
                        break;
                    default:
                        sections[secid].questions[e.target.dataset.id][e.target.dataset.name] = e.target.value;
                        this.setState({ sections });
                }
            }
        } else {
            let sections = [...this.state.sections];
            const { name, value } = e.target;
            let { outcomesRelatedQues } = this.state;
            switch (name) {
                case 'related':
                    let val = sections[secid].related;
                    const val1 = value === val ? (value === 'true' ? 'false' : 'true') : 'false';
                    sections[secid][name] = val1;
                    if (val1 === 'false') {
                        sections = sections.map((sec) => {
                            return {
                                ...sec,
                                questions: sec.questions.map((ques) => {
                                    return {
                                        ...ques,
                                        suggested_jump:
                                            Array.isArray(ques.suggested_jump) && ques.suggested_jump.length > 0
                                                ? ques.suggested_jump.map((s, i) => {
                                                    return {
                                                        ...s,
                                                        jumpto: Array.isArray(s?.jumpto) && s?.jumpto.length > 0 ? s?.jumpto.filter((j) => j !== secname) : []
                                                    };
                                                })
                                                : []
                                    };
                                })
                            };
                        });
                    }
                    break;
                case 'is_odm':
                    let actualValue = sections[secid].is_odm;
                    const val2 = value === actualValue ? (value === 'yes' ? 'no' : 'yes') : 'no';
                    sections[secid][name] = val2;

                    if (val2 === 'no') {
                        sections = this.removeAllOdm(sections, secname, outcomesRelatedQues);
                    }
                    break;
                default:
                    if(name) {
                    sections[secid][name] = value;
                    }
            }

            this.setState({
                sections
            });
        }
    };

    newItemId = () => {
        const id = this.state.lastItemId;
        //this.state.lastItemId += 1;
        this.setState((prevState) => ({
            ...prevState,
            lastItemId: prevState.lastItemId + 1
        }));
        return id;
    };

    newSectionId = () => {
        const id = this.state.lastSectionId;
        //this.state.lastSectionId += 1;
        this.setState((prevState) => ({
            ...prevState,
            lastSectionId: prevState.lastSectionId + 1
        }));
        return id;
    };

    addQuestion = (e) => {
        e.preventDefault();
        const secid = e.target.dataset.id;
        const quesid = Number(e.target.dataset.idx);
        this.setState({
            lastItemId: quesid
        });
        const id = this.newItemId();
        this.setState((prevState) => ({
            ...prevState,
            show: {
                ...prevState.show,
                [quesid + 1]: !prevState.show[quesid + 1]
            }
        }));

        let ques = {
            question_id: quesid + 1,
            question: '',
            description: '',
            field_type: '1',
            answer_type: '',
            suggested_answers: [],
            suggested_jump: [],
            child_questions: [],
            validation1: '',
            validation2: '',
            validation3: '',
            validation4: '',
            exact_match: true,
            error_msg: '',
            error_msg2: '',
            spl_characters: '',
            re_type: '',
            is_to_be_mask: '',
            is_odm: 'no',
            related: 'no',
            required: 'yes',
            flag: '0',
            is_to_be_search: 'no',
            is_parent: 'no',
            is_child: 'no',
            add_type: 'normal',
            for_ml_model: 'no',
            is_ans_dep_on_prev_que_ans: 'no',
            is_for_hiding: 'no',
            for_prediction_screen: 'no',
            api_call: 'no',
            ml_model_path: 'no',
            ml_input_fields_path: 'no',
            is_primary_key: false
        };
        let sections = [...this.state.sections];
        sections[secid].questions = [...sections[secid].questions, ques];
        let { readOnly } = this.state;

        if (readOnly) {
            this.setState({
                [`Ques-${quesid + 1}`]: true
            });
        }
        this.setState({ sections });
    };

    addSection = () => {
        let sections = [...this.state.sections];
        let sectionResponse = sections.find((section) => section.section === 'Outcomes');
        let index = sections.findIndex((section) => section.section === 'Outcomes');
        sections.push({
            section: "",
            section_id: 0,
            related: "false",
            is_odm: "no",
            questions: [
                {
                    question_id: 0,
                    question: "",
                    description: "",
                    field_type: "1",
                    answer_type: "",
                    suggested_answers: [],
                    suggested_jump: [],
                    child_questions: [],
                    validation1: "",
                    validation2: "",
                    validation3: "",
                    validation4: "",
                    exact_match: true,
                    error_msg: "",
                    error_msg2: "",
                    spl_characters: "",
                    re_type: "",
                    is_to_be_mask: "",
                    is_odm: "no",
                    related: "no",
                    required: "yes",
                    flag: "0",
                    is_to_be_search: "no",
                    is_parent: "no",
                    is_child: "no",
                    add_type: "normal",
                    for_ml_model: "no",
                    for_prediction_screen: "no",
                    api_call: "no",
                    ml_model_path: "no",
                    ml_input_fields_path: "no",
                    is_ans_dep_on_prev_que_ans: "no",
                    is_for_hiding: "no",
                    is_primary_key: false
                }
            ]
        });
        if (sectionResponse) {
            if (index >= 0) {
                sections.splice(index, 1);
                sections.push(sectionResponse);
            }
        }

        this.setState({ sections }, () => this.alterSectionId());
    };

    onPreviuos = () => {
        let id = this.state.lastSectionId;
        this.setState({
            lastSectionId: id - 1
        });
        if (this.state.expandAll) {
            const questionids = this.state.sections[id - 1].questions.map((item, index) => index);
            let showValues = {};
            questionids.map((item) => {
                return (showValues[item] = true);
            });

            this.setState((prevState) => ({
                ...prevState,
                show: showValues
            }));
        } else {
            this.setState({ show: {} });
        }
    };

    onNext = () => {
        let id = this.state.lastSectionId;
        this.setState({
            lastSectionId: id + 1
        });
        if (this.state.expandAll) {
            const questionids = this.state.sections[id + 1].questions.map((item, index) => index);
            let showValues = {};
            questionids.map((item) => {
                return (showValues[item] = true);
            });

            this.setState((prevState) => ({
                ...prevState,
                show: showValues
            }));
        } else {
            this.setState({ show: {} });
        }
    };

    onLast = () => {
        let id = this.state.sections.length - 1;
        let sections = [...this.state.sections];
        sections.map((section, i) => (section.section_id = i));
        this.setState({
            sections,
            lastSectionId: id
        });
        if (this.state.expandAll) {
            const questionids = this.state.sections[id].questions.map((item, index) => index);
            let showValues = {};
            questionids.map((item) => {
                return (showValues[item] = true);
            });

            this.setState((prevState) => ({
                ...prevState,
                show: showValues
            }));
        } else {
            this.setState({ show: {} });
        }
    };
    handlePreview = async (e) => {
        e.preventDefault();
        this.setState({
            isPreview: true,
            isOpen: true
        });
    };

    UniqueIdSearchFieldService = () => {
        let unique_id = false;
        let searchble_item = false;

        let sections = [...this.state.sections];
        let finalSections = sections.flatMap((sections) => sections.questions);

        unique_id = finalSections.some((item) => item.is_primary_key && item.is_primary_key);
        searchble_item = finalSections.some((item) => item.is_to_be_search && item.is_to_be_search === 'yes');

        this.setState((prevState) => ({
            ...prevState,
            set_unique_id: unique_id,
            set_searchble_item: searchble_item
        }));
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        let sections = [...this.state.sections];
        let relatedSections = [...this.state.relatedSections];
        let relatedQuestions = [...this.state.relatedQuestions];

        //Find : Related Sections
        sections.forEach((section, id) => {
            let length = 0;
            section.related === 'true' &&
                sections.forEach((section1, idx) => {
                    section1.questions.forEach((question, idy) => {
                        question.suggested_jump &&
                            question.suggested_jump.forEach((suggestjump, idz) => {
                                suggestjump != null &&
                                    suggestjump.jumpto &&
                                    suggestjump.jumpto.forEach((jmpto, idz1) => {
                                        jmpto === sections[id].section && length++;
                                    });
                            });
                    });
                });
            if (length === 0) {
                section.related === 'true' && relatedSections.push({ section: section.section, id: id + 1 });
            }
        });
        for (var a in relatedSections) {
            relatedSections.length > 0 &&
                toast.warning('Section ' + relatedSections[a].id + ': *' + relatedSections[a].section.toUpperCase() + '* is Related but not used in any other jump while configuration', {
                    position: toast.POSITION.TOP_CENTER
                });
        }
        //Find : Related Question 1
        var newQues = [];
        let validatedQuestions = [];
        sections.forEach((section, id) => {
            section.questions.filter((ques, id1) => {
                ques.related === 'yes' && newQues.push({ sectionid: id, quesid: id1 });
                if (ques.answer_type === 'NUMBER' && (ques.validation1 === '' || ques.validation2 === '' || ques.error_msg === '')) {
                    validatedQuestions.push({ id: id, idx: id1 });
                } else if (ques.answer_type === 'TEXT') {
                    if (ques.validation2 !== '' && (ques.validation2 === '' || ques.error_msg === '')) {
                        validatedQuestions.push({ id: id, idx: id1 });
                    }
                    if (ques.validation3 || ques.validation4) {
                        if (ques.error_msg2 === '') {
                            validatedQuestions.push({ id: id, idx: id1 });
                        }
                    }
                }
            });
        });
        newQues &&
            newQues.forEach((section) => {
                let id = section.sectionid;
                let id1 = section.quesid;
                let reqArray = [];
                sections.forEach((sec) => {
                    sec.questions.forEach((question, idy) => {
                        question.suggested_jump &&
                            question.suggested_jump.map((suggestedjump) => {
                                return (
                                    suggestedjump != null &&
                                    suggestedjump.question_jumpto &&
                                    suggestedjump.question_jumpto.map((questionjumpto) => {
                                        return questionjumpto === sections[id].questions[id1].question && reqArray.push(questionjumpto);
                                    })
                                );
                            });
                    });
                });
                reqArray.length === 0 && relatedQuestions.push(section);
                reqArray.length > 0 && relatedQuestions.slice(reqArray.indexOf(section));
                //reqArray.length == 0 ? alertSections1.push(section) : alertSections1.slice(reqArray.indexOf(section))
            });
        //Find : Related Question 2
        for (var b in relatedQuestions) {
            let id = relatedQuestions[b].sectionid + 1;
            let id1 = relatedQuestions[b].quesid + 1;
            relatedQuestions.length > 0 &&
                toast.warning('Section ' + id + ': Question ' + id1 + ' **' + sections[id - 1].questions[id1 - 1].question.toUpperCase() + '** is Related but not used in any other question jump while configuration', {
                    position: toast.POSITION.TOP_CENTER
                });
        }
        //Validate : Questions answer type Number
        for (var c in validatedQuestions) {
            let id = validatedQuestions[c].id + 1;
            let idx = validatedQuestions[c].idx + 1;
            validatedQuestions.length > 0 && toast.error('Section ' + id + ': Question ' + idx + ' **' + sections[id - 1].questions[idx - 1].question.toUpperCase() + '** Answer should be validated properly', { position: toast.POSITION.TOP_CENTER });
        }

        //FIND : Outcomes Section
        let lastSectionValue = this.state.sections.find((section) => section.section === 'Outcomes');
        const findIndex = this.state.sections.findIndex((section) => section.section === 'Outcomes');
        if (findIndex >= 0) {
            let sections = [...this.state.sections];
            sections.splice(findIndex, 1);
            this.setState({ sections });
            this.setState((prevState) => ({
                ...prevState,
                sections: [...prevState.sections, lastSectionValue]
            }));
        }
        //Making : Suggestions Jump values proper
        sections = [...this.state.sections];

        //Sorting : remove empty id && values from Suggested Answer and suggested jump
        sections = sections.map((sec) => {
            return {
                ...sec,
                questions: sec.questions.map((ques) => {
                    return {
                        ...ques,
                        suggested_answers: Array.isArray(ques.suggested_answers) && ques.suggested_answers.length > 0 ? ques.suggested_answers.filter((item, i) => item.id !== '' && item.value !== '') : [],
                        suggested_jump: Array.isArray(ques.suggested_jump) && ques.suggested_jump.length > 0 ? ques.suggested_jump.filter((item, i) => item.answer !== '') : []
                    };
                })
            };
        });

        //Remove : removing validation1 to type text fields as per new validations
        sections = sections.map((sec) => {
            return {
                ...sec,
                questions: sec.questions.map((ques) => {
                    if (ques.answer_type?.toLowerCase() === 'text') {
                        return {
                            ...ques,
                            validation1: ''
                        };
                    } else {
                        return {
                            ...ques
                        };
                    }
                })
            };
        });
        let { Config } = this.props.match.params;
        let data = {
            customer: this.state.Org_id,
            sections: sections,
            config_type: Config
        };
        if (!this.state.set_unique_id) {
            toast.warning('There is no unique id selected. One unique id must be selected.', {
                position: toast.POSITION.TOP_CENTER
            });
        }

        if (!this.state.set_searchble_item) {
            toast.warning('There is no searchble question selected. One searchble question must be selected.', {
                position: toast.POSITION.TOP_CENTER
            });
        }
        this.setState({ sections });
        if (!this.state.hasError && relatedSections.length === 0 && relatedQuestions.length === 0 && validatedQuestions.length === 0 && this.state.set_unique_id && this.state.set_searchble_item) {
            this.setState({ isLoading: true });
            const response = await saveClientConfigure(data);
            if (response?.status === 'success') {

                toast.info(`Questions configured successfully. `, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
                setTimeout(() => {
                    this.setState({ isLoading: false });
                    window.location.reload()
                }, 3000)
            } else {
                this.setState({ isLoading: false });
                toast.error(response?.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
            }
        } else {
            relatedSections.length > 0 || relatedQuestions.length > 0
                ? toast.error('The Data was not Submitted Due to improper usage of Related Sections or due to Related Questions ', {
                    position: 'top-center',
                    autoClose: 5000
                })
                : toast.error(this.state.err_msg, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
        }
    };

    handleClose = () => {
        this.setState({
            isOpen: false,
            isPreview: false,
            answerModal: {
                isOpen: false,
                sectionIndex: '',
                questionIndex: '',
                question: '',
                answerIndex: ''
            }
        });
    };
    jumpOptions = (id, idx, idy) => {
        let jumpOpt = [];
        this.state.sections &&
            this.state.sections
                .filter((sec, key) => key > id && sec.related === 'true')
                .map((q, i) => {
                    let exactSection = Number(q.section_id) + 1 + '. ' + q.section;
                    jumpOpt.push({
                        value: q.section,
                        label: (
                            <div data-toggle="tooltip" data-placement="top" title={exactSection} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                                {exactSection}
                            </div>
                        ),
                        id: i,
                        isOutcomesQues: false
                    });
                });
        let lenJumpOpt = jumpOpt.length;
        this.state.sections[id].is_odm === 'yes' &&
            jumpOpt.push({
                value: 'Outcomes',
                label: (
                    <div data-toggle="tooltip" data-placement="top" title={'Outcomes'} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                        <span>Outcomes</span>
                    </div>
                ),
                id: lenJumpOpt,
                isOutcomesQues: true
            });
        return jumpOpt;
    };
    Question_jumpOptions = (id, idx, idy) => {
        const { outcomesRelatedQues } = this.state;
        let options = [];
        this.state.sections[id].questions
            .filter((sec, key) => key > idx && sec.related === 'yes')
            .map((q, i) => {
                let exactQuestion = Number(q.question_id) + 1 + '. ' + q.question;
                options.push({
                    value: q.question,
                    label: (
                        <div data-toggle="tooltip" data-placement="top" title={exactQuestion} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                            <span onClick={() => this.handleOptionClick(id, idx, idy, q.question)}> {exactQuestion} </span>
                        </div>
                    ),
                    id: i,
                    isOutcomesQues: false
                });
            });
        let lenOptions = options.length;

        this.state.sections[id].questions[idx].is_odm === 'yes' &&
            outcomesRelatedQues &&
            outcomesRelatedQues.map((ques, i) =>
                options.push({
                    value: ques,
                    label: (
                        <div data-toggle="tooltip" data-placement="top" title={ques} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                            <span onClick={() => this.handleOptionClick(id, idx, idy, ques)}> {ques} </span>
                        </div>
                    ),
                    id: lenOptions + i,
                    isOutcomesQues: true
                })
            );
        return options;
    };

    ChildQuestionOptions = (id, idx) => {
        let options = [];
        this.state.sections[id].questions
            .filter((sec, key) => key > idx && sec.is_child === 'yes')
            .map((q, i) => {
                let exactQuestion = Number(q.question_id) + 1 + '. ' + q.question;
                options.push({
                    value: q.question,
                    label: (
                        <div data-toggle="tooltip" data-placement="top" title={exactQuestion} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                            {exactQuestion}
                        </div>
                    ),
                    id: i,
                    isOutcomesQues: false
                });
            });
        return options;
    };

    ReferralStatusOptions = (data) => {
        let options = [
            {
                value: '',
                label: 'Select',
                id: ''
            }
        ];
        data &&
            data.map((item, i) => {
                options.push({
                    value: item,
                    label: (
                        <div data-toggle="tooltip" data-placement="top" title={item} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                            {item}
                        </div>
                    ),
                    id: i
                });
            });
        return options;
    };

    multihandleChange = (id, idx, idy) => (e) => {
        let sections = [...this.state.sections];
        let jumpValues = Array.isArray(e) ? e.map((arr) => arr.value) : [];
        sections[id].questions[idx].suggested_jump[idy] =
            jumpValues.length === 0
                ? {
                    ...sections[id].questions[idx].suggested_jump[idy],
                    jumpto: []
                }
                : {
                    answer: sections[id].questions[idx].suggested_answers[idy].value,
                    jumpto: jumpValues,
                    question_jumpto: sections[id].questions[idx].suggested_jump[idy] ? sections[id].questions[idx].suggested_jump[idy].question_jumpto : [],
                    is_deleted: sections[id].questions[idx].suggested_answers[idy].is_deleted,
                    mapping_answers: sections[id].questions[idx].suggested_jump[idy]?.mapping_answers
                };
        this.setState({
            sections
        });
    };

    Question_multihandleChange = (id, idx, idy) => (e) => {
        let sections = [...this.state.sections];
        let jumpValues = Array.isArray(e) ? e.map((arr) => arr.value) : [];
        const suggestedJumpMethod = () => {
            let suggested_jump = [];
            sections[id].questions[idx].suggested_answers.map((item, index) => {
                let mapping_answers = sections[id].questions[idx].suggested_jump[idy].mapping_answers.filter((item) => item && jumpValues.includes(item.question));

                suggested_jump.push({
                    answer: sections[id].questions[idx].suggested_answers[index].value,
                    jumpto: sections[id].questions[idx].suggested_jump[index].jumpto,
                    question_jumpto: item.value === sections[id].questions[idx].suggested_jump[idy].answer && !item.is_deleted ? jumpValues : sections[id].questions[idx].suggested_jump[index].question_jumpto,
                    is_deleted: sections[id].questions[idx].suggested_answers[index].is_deleted,
                    mapping_answers: index === idy ? mapping_answers : sections[id].questions[idx].suggested_jump[index].mapping_answers
                });
            });
            return suggested_jump;
        };

        sections[id].questions[idx].suggested_jump = suggestedJumpMethod();

        this.setState({
            sections
        });
    };

    Child_Question_handleChange = (id, idx, idy) => (e) => {
        let sections = [...this.state.sections];
        let jumpValues = Array.isArray(e) ? e.map((arr) => arr.value) : [];
        sections[id].questions[idx].child_questions[idy] =
            jumpValues.length === 0
                ? {
                    ...sections[id].questions[idx].child_questions[idy],
                    questions: []
                }
                : {
                    answer: sections[id].questions[idx].suggested_answers[idy].value,
                    questions: jumpValues
                };
        this.setState({
            sections
        });
    };

    Referral_Status_HandleChange = (id, idx, idy, data) => (e) => {
        let sections = [...this.state.sections];
        let jumpValues = e.value;
        sections[id].questions[idx].suggested_answers[idy].value = jumpValues;
        sections[id].questions[idx].suggested_jump[idy].answer = jumpValues;

        this.setState({
            sections
        });
    };

    Map_Question_handleChange = (id, idx) => (e) => {
        let sections = [...this.state.sections];
        sections[id].questions[idx].fml_question_map = e.value;
        this.setState({
            sections
        });
    };

    toggleListItem(itemId) {
        this.setState((prevState) => ({
            ...prevState,
            show: {
                ...prevState.show,
                [itemId]: !prevState.show[itemId]
            }
        }));
    }
    //To handle expand and collapse of question block
    handleExpand = (e) => {
        e.preventDefault();
        let id = this.state.lastSectionId;
        if (this.state.expandAll) {
            this.setState((prevState) => ({
                ...prevState,
                show: {},
                expandAll: false
            }));
        } else {
            const questionids = this.state.sections[id].questions.map((item, index) => index);
            let showValues = {};
            questionids.map((item) => {
                return (showValues[item] = true);
            });

            this.setState((prevState) => ({
                ...prevState,
                show: showValues,
                expandAll: true
            }));
        }
    };
    // to handle drag effect
    handleDragEffect(itemId, value) {
        this.setState((prevState) => ({
            ...prevState,
            showEffect: {
                ...prevState.showEffect,
                [itemId]: !prevState.showEffect[itemId]
            }
        }));
    }

    sortHandler = (id, idx, type) => {
        let sections = [...this.state.sections];
        //Sorting : Suggested Answer sorting
        sections[id].questions[idx].suggested_answers = sections[id].questions[idx].suggested_answers.length > 0 ? sections[id].questions[idx].suggested_answers.sort((a, b) => (type === 'asc' ? a.value.localeCompare(b.value) : b.value.localeCompare(a.value))) : [];

        //Sorting : Suggested Jump sorting
        sections[id].questions[idx].suggested_jump = sections[id].questions[idx].suggested_jump.length > 0 ? sections[id].questions[idx].suggested_jump.sort((a, b) => (type === 'asc' ? a.answer.localeCompare(b.answer) : b.answer.localeCompare(a.answer))) : [];

        //MOving: find non sort item from list and move it to bottom
        let answersResponse = sections[id].questions[idx].suggested_answers.filter((item) => QuestionsConfig.Suggested_answer_list.includes(item.value?.toLowerCase()));
        let jumpResponse = sections[id].questions[idx].suggested_jump.filter((item) => QuestionsConfig.Suggested_answer_list.includes(item.answer?.toLowerCase()));
        sections[id].questions[idx].suggested_answers = sections[id].questions[idx].suggested_answers.filter((item) => !QuestionsConfig.Suggested_answer_list.includes(item.value?.toLowerCase()));
        sections[id].questions[idx].suggested_jump = sections[id].questions[idx].suggested_jump.filter((item) => !QuestionsConfig.Suggested_answer_list.includes(item.answer?.toLowerCase()));

        if (answersResponse.length > 0) {
            sections[id].questions[idx].suggested_answers = [...sections[id].questions[idx].suggested_answers, ...answersResponse];
            sections[id].questions[idx].suggested_jump = [...sections[id].questions[idx].suggested_jump, ...jumpResponse];
        }

        this.setState({
            ...sections
        });
    };

    handleOptionClick = (id, idx, idy, question) => {
        if (this.state.sections[id].questions[idx].suggested_jump[idy]?.question_jumpto?.includes(question)) {
            this.setState((prevState) => ({
                ...prevState,
                answerModal: {
                    isOpen: true,
                    sectionIndex: id,
                    questionIndex: idx,
                    question: question,
                    answerIndex: idy
                }
            }));
        }
    };

    appendUploadedAnswers = (result, id, idx, idy, finalList) => {
        const { sections } = this.state;

        if (result.status === 'success') {
            if (finalList.length > 0) {
                let findIndexOfJump = sections[id].questions[idx].suggested_jump[idy].question_jumpto.findIndex((item) => item === finalList[0].question);
                let uploadedList = result.response.map((item) => item.value);
                let addMapDetails = {
                    question: finalList[0].question,
                    answers: uploadedList
                };
                sections[id].questions[idx].suggested_jump[idy].mapping_answers[findIndexOfJump] = addMapDetails;
            } else {
                let suggested_jump = [];
                result.response.map((item) =>
                    suggested_jump.push({
                        answer: item.value,
                        jumpto: [],
                        question_jumpto: [],
                        mapping_answers: [],
                        is_deleted: false
                    })
                );
                sections[id].questions[idx].suggested_answers = result.response;
                sections[id].questions[idx].suggested_jump = suggested_jump;
            }
            this.setState({
                ...sections
            });
            toast.info(result?.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        } else {
            toast.error(result?.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };

    MapAnswerList = (question) => {
        const { sections } = this.state;
        if (question) {
            let mapAnswers = sections
                .find((sec) => sec.questions.find((ques) => ques.question === question))
                ?.questions.find((ques) => ques.question === question)
                ?.suggested_answers.filter((sug) => sug && sug.value && !sug.is_deleted) // Filter out deleted answers
                .map((sug) => sug.value);

            return [{ question: question, list: mapAnswers }];
        } else {
            return [];
        }
    };

    getOptions = (modalData) => {
        if (modalData.question !== '') {
            let { sectionIndex, questionIndex, answerIndex, question } = modalData;
            let options = [];
            if (this.state.sections[sectionIndex].questions[questionIndex].suggested_jump[answerIndex]?.mapping_answers.length > 0) {
                let result = this.state.sections[sectionIndex].questions[questionIndex].suggested_jump[answerIndex]?.mapping_answers.find((item) => item?.question === question);
                result &&
                    result.answers.map((item, i) => {
                        options.push({
                            value: item,
                            label: item
                        });
                    });
            }
            return options;
        }
    };

    Map_Answers_handleChange = (modalData) => (e) => {
        let sections = [...this.state.sections];
        let { sectionIndex, questionIndex, answerIndex, question } = modalData;
        let Values = Array.isArray(e) ? e.map((arr) => arr.value) : [];
        let findIndexOfJump = sections[sectionIndex].questions[questionIndex].suggested_jump[answerIndex].question_jumpto.findIndex((item) => item === question);
       
        if (findIndexOfJump?.toString()) {
            if (Values.length === 0) {
                sections[sectionIndex].questions[questionIndex].suggested_jump[answerIndex].mapping_answers = [];
            } else {
                sections[sectionIndex].questions[questionIndex].suggested_jump[answerIndex].mapping_answers[findIndexOfJump] = {
                    question: question,
                    answers: Values
                };
            }
            this.setState({
                ...sections
            });
        }
    };

    loadingHandler = (isLoading) => {
        this.setState((prevState) => ({
            ...prevState,
            isLoading: isLoading
        }));
    };

    MultipleFlagSelectHandler = (selectedOptions,id, idx)  => {
        let sections = [...this.state.sections];
         let Values = Array.isArray(selectedOptions) ? selectedOptions.map((arr) => arr.value) : [];
        let deciderObj = selectedOptions.find((item) => item.hasOwnProperty('payLoad') && Object.keys(item.payLoad).length > 0 )?.payLoad 
            deciderObj = deciderObj ? deciderObj : Values.includes('is_ans_dep_on_prev_que_ans') ?  {file_path: '', deciders: ['']}: {}
        
        sections[id].questions[idx]['deciders_obj'] = deciderObj;
        
        flagList.map((item) => { 
            if (Values?.includes(item.value)) {
                sections[id].questions[idx][item.value] = 'yes';
            } else {
                sections[id].questions[idx][item.value] = 'no';
            }
        });

        // Setting all flags to no if is_for_hiding is yes except for_ml_model
        if (sections[id].questions[idx]['is_for_hiding'] === 'yes') {
            flagList.map((item) => {
                if (!['for_ml_model', 'is_for_hiding'].includes(item.value)) {
                    sections[id].questions[idx][`${item.value}`] = 'no';
                }
            });
        }

        //Finding Question - related : 'no' or is_odm : 'no' or required: 'yes'

        if (sections[id].questions[idx]['related'] === 'no') {
            sections = sections.map((sec) => {
                return {
                    ...sec,
                    questions: sec.questions.map((ques) => {
                        return {
                            ...ques,
                            suggested_jump:
                                Array.isArray(ques.suggested_jump) && ques.suggested_jump.length > 0
                                    ? ques.suggested_jump.map((s, i) => {
                                        return {
                                            ...s,
                                            question_jumpto: Array.isArray(s?.question_jumpto) && s?.question_jumpto.length > 0 ? s?.question_jumpto.filter((j) => j !== sections[id].questions[idx].question) : []
                                        };
                                    })
                                    : []
                        };
                    })
                };
            });
        }

        if (sections[id].questions[idx]['is_odm'] === 'no') {
            let { outcomesRelatedQues } = this.state;
            sections = this.removeQuestionOdm(sections, id, sections[id].section, outcomesRelatedQues);
        }
        // removing child questions
        if (sections[id].questions[idx]['is_parent'] === 'no') {
            sections[id].questions[idx]['child_questions'] = [];
        }

        if (sections[id].questions[idx]['is_parent'] === 'yes') {
            sections[id].questions[idx]['is_child'] = 'no';
        }

        if (sections[id].questions[idx]['is_child'] === 'yes') {
            sections[id].questions[idx]['is_parent'] = 'no';
        }
        if (sections[id].questions[idx]['is_child'] === 'no') {
            sections = sections.map((sec) => {
                return {
                    ...sec,
                    questions: sec.questions.map((ques) => {
                        return {
                            ...ques,
                            child_questions: ques.child_questions.map((child) => {
                                return {
                                    ...child,
                                    questions: Array.isArray(child?.questions) && child?.questions.length > 0 ? child?.questions.filter((j) => j !== sections[id].questions[idx].question) : []
                                };
                            })
                        };
                    })
                };
            });
        }
        
        this.setState(
            {
                sections
            },
            () => {
                //Finding : Outcomes all related "yes" question
                this.findOutcomesRelatedQues();
                this.UniqueIdSearchFieldService();
            }
        );
    };

    updateState = async (data) => {
        await this.setState((prevState) => ({
            ...prevState,
            ...data
        }));
    };

    onUploadModelFiles = async (acceptedFiles, id, idx, type, fileType) => {
        let selectedFile = acceptedFiles[0];
        const selectedFileType = selectedFile?.name?.split('.')[1];

        if (selectedFileType === fileType) {
            // Create a new JSON object containing the file details
            let { Config } = this.props.match.params;
            let sections = [...this.state.sections];

            let data = {
                customer: this.state.Org_id,
                config_type: Config,
                question_id: sections[id].questions[idx].id,
                ml_file_asset: selectedFile
            };

            await uploadMLModelFiles(data, function (result) {
                if (result.status === 'success') {
                    sections[id].questions[idx][`${type}`] = result.response;

                    this.setState({ ...sections });
                } else {
                    alert(`Please upload valid file. `);
                }
            });
        } else {
            alert(`Please upload .${fileType} file. `);
        }
    };

    disableQuestion = (data) => {
        let disabled = false;
        if (data.for_ml_model === 'yes') {
            disabled = true;
        } else if (data.sub_config_message && data.sub_config_message !== '') {
            disabled = true;
        }
        return disabled;
    }

    render() {
        toast.configure();
        let { sections, isPreview, logoPath, header_color, isLoading, org_type, fmlBasicRequirmentList, configured_sections, is_configuration_available } = this.state;
        let id = this.state.lastSectionId;
        let { Config } = this.props.match.params;
        const sectionLength = this.state.sections.length - 1;
        const OutcomesStaticQues = ['Referral Status', 'Foster Family', 'Permanency Achieved', 'Permanency Maintained'];
        const NonEditSection = ['Outcomes'];
        const findIndex = this.state.sections.findIndex((section) => section.section === 'Outcomes');
        //Removing Duplicate values
        const StaticQuesIndex = [];

        OutcomesStaticQues.filter((ques1, i) => this.state.sections.length > 1 && this.state.sections[id]?.questions?.some((ques2, j) => ques1 === ques2.question && StaticQuesIndex.push(ques2.question_id)));
        
        if (isPreview) {
            return (
                <Modal isOpen={this.state.isOpen} ariaHideApp={false} onRequestClose={this.handleClose} style={customStyles} scrollable="true" contentLabel="Example Modal">
                    <Preview DynamicQuestions={this.state.sections} logoPath={logoPath} headerColor={header_color} />
                </Modal>
            );
        } else {
            return (
                <Fragment>
                    <LoaderComponent isLoading={isLoading} />
                    {this.state.answerModal.isOpen ? (
                        <Modal isOpen={this.state.answerModal.isOpen} ariaHideApp={false} style={customStyles} scrollable="true" contentLabel="Example Modal">
                            <div className="d-flex justify-content-end align-items-center p-2" onClick={this.handleClose}>
                                <button className="btn btn-primary">Close</button>
                            </div>
                            <div>
                                <h2 className="d-flex justify-content-center align-items-center modal-heading">Download & Upload Answers List</h2>
                                <div className="d-flex justify-content-center align-items-center p-2">
                                    Download answers of <strong>&nbsp; {this.state.answerModal.question} &nbsp;</strong> and update list and upload below.{' '}
                                </div>
                                <div className="download-box">
                                    <UploadOrDownloadBulkList id={this.state.answerModal.sectionIndex} idx={this.state.answerModal.questionIndex} idy={this.state.answerModal.answerIndex} appendUploadedAnswers={this.appendUploadedAnswers} MapAnswerList={this.MapAnswerList(this.state.answerModal.question)} sections={this.state.sections} />
                                </div>
                                {this.state.sections[this.state.answerModal.sectionIndex].questions[this.state.answerModal.questionIndex].suggested_jump[this.state.answerModal.answerIndex]?.mapping_answers?.length > 0 && (
                                    <Fragment>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <strong>&nbsp; {this.state.answerModal.question}</strong>&nbsp; mapped answers.{' '}
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <Select options={this.getOptions(this.state.answerModal)} isMulti onChange={this.Map_Answers_handleChange(this.state.answerModal)} value={this.getOptions(this.state.answerModal)?.map((option) => ({ value: option.value, label: option.label }))} />
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </Modal>
                    ) : (
                        <div className="container-fluid mb-5">
                            <SubHeader id={this.props.match.params.id} config={this.props.match.params.Config} DownloadToolConfiguration={DownloadToolConfiguration} UploadToolConfiguration={UploadToolConfiguration} loadingHandler={this.loadingHandler} isLoading={this.state.isLoading} />
                            {this.state.sections.length > 0 ? (
                                <div style={{ border: '1px solid #007bff' }}>
                                    <form onChange={this.handleChange} onSubmit={''} className="m-3">
                                        <div className="form-group field-row">
                                            <label className="col-sm-1 col-form-label font-weight-bold " htmlFor="question">
                                                Section {id + 1}:
                                            </label>
                                            <div className="col-sm-5">
                                                <input className="form-control" type="text" name="section" id="section" data-secid={id} value={sections[id].section} readOnly={NonEditSection.includes(sections[id].section) ? true : false} />
                                            </div>
                                            <div>
                                                <button className="btn btn-primary" onClick={this.handleExpand}>
                                                    {this.state.expandAll ? 'Collapse All' : 'Expand All'}
                                                </button>
                                            </div>
                                            {id === 0 || id === findIndex ? (
                                                ''
                                            ) : (
                                                <React.Fragment>
                                                    <div className="form-check form-check-inline col-sm-1 ml-3">
                                                        <input type="checkbox" className="form-check-input" name="related" data-secid={id} data-secname={this.state.sections[id] && this.state.sections[id].section} checked={this.state.sections[id].related === 'true'} value="false" />
                                                        <label className="form-check-label font-weight-bold">Related</label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-sm-1" title="Outcomes Decision Maker">
                                                        <input type="checkbox" className="form-check-input" name="is_odm" data-secid={id} data-secname={this.state.sections[id] && this.state.sections[id].section} checked={this.state.sections[id].is_odm === 'yes'} value="no" />
                                                        <label className="form-check-label font-weight-bold">Odm</label>
                                                    </div>
                                                    <div
                                                        style={{
                                                            position: 'relative',
                                                            top: '-5px',
                                                            right: '-0px',
                                                            width: '50px',
                                                            height: '44px'
                                                        }}
                                                        className="ml-3"
                                                        data-id={id}
                                                        onClick={this.sectionDelete(id)}
                                                    >
                                                        <Link data-id={id} className="btn btn-danger" to="#">
                                                            <i data-id={id} className="fa fa-trash-o fa-lg"></i>
                                                        </Link>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>

                                        <DragDropContext
                                            onDragEnd={async (param, props) => {
                                                const srcI = param.source.index;
                                                const desI = param.destination?.index;

                                                if (desI || desI === 0) {
                                                    sections[id].questions.splice(desI, 0, sections[id].questions.splice(srcI, 1)[0]);
                                                    await this.saveList(sections[id].questions);
                                                    this.alterID(id);
                                                }
                                            }}
                                        >
                                            <Droppable droppableId="droppable-1">
                                                {(provided, _) => (
                                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                                        {sections[id].questions.map((val, idx) => {
                                                            let catId = `cat-${idx}`,
                                                                answerId = `answer-${idx}`,
                                                                descId = `description-${idx}`,
                                                                fml_question_map_Id = `fml_question_map-${idx}`,
                                                                validationId1 = `validation1-${idx}`,
                                                                validationId2 = `validation2-${idx}`,
                                                                validationId3 = `validation3-${idx}`,
                                                                validationId4 = `validation4-${idx}`,
                                                                typeId = `type-${idx}`,
                                                                errorId = `error-${idx}`,
                                                                error_msg2 = `error_msg2-${idx}`,
                                                                splCharactersID = `spl_characters-${idx}`,
                                                                fieldTypeId = `fieldType-${idx}`,
                                                                is_parent_Id = `is_parent-${idx}`,
                                                                is_child_Id = `is_child-${idx}`,
                                                                add_type_id = `add_type-${idx}`,
                                                                exact_match_id = `exact_match-${idx}`,
                                                                ml_model_path = `ml_model_path-${idx}`,
                                                                ml_input_fields_path = `ml_input_fields_path-${idx}`,
                                                                is_primary_key = `is_primary_key-${idx}`;
                                                            return (
                                                                <div className="mb-2" key={val.question_id}>
                                                                    <Draggable key={val.question_id} draggableId={'draggable-' + val.question_id} index={idx}>
                                                                        {(provided, snapshot) => (
                                                                            <>
                                                                                <div
                                                                                    className="list-group-item col-10  mt-1 question-container"
                                                                                    key={idx}
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    style={{
                                                                                        ...provided.draggableProps.style,
                                                                                        boxShadow: snapshot.isDragging ? '0 0 .4rem #666' : '0 2px 5px rgba(0, 0.3, 0, 0.3)',

                                                                                        borderLeft: (this.state.showEffect[idx] || snapshot.isDragging) && '5px solid #007BFF',
                                                                                        borderTopLeftRadius: (this.state.showEffect[idx] || snapshot.isDragging) && '8px',
                                                                                        borderTopRightRadius: (this.state.showEffect[idx] || snapshot.isDragging) && '8px'
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            position: 'absolute',
                                                                                            top: '-5px',
                                                                                            left: '-5px',
                                                                                            display: 'flex',
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center',
                                                                                            cursor: 'pointer',
                                                                                            borderRadius: '50%',
                                                                                            height: '30px',
                                                                                            width: '30px',
                                                                                            backgroundColor: '#007bff'
                                                                                        }}
                                                                                        onClick={() => this.toggleListItem(idx)}
                                                                                    >
                                                                                        {this.state.show[idx] ? <BsFillCaretUpFill color="#fff" /> : <BsFillCaretDownFill color="#fff" />}
                                                                                    </div>
                                                                                    <div className="m-3 p-3">
                                                                                        <div className="form-group field-row">
                                                                                            <label className="col-sm-2 col-form-label font-weight-bold " htmlFor={catId}>
                                                                                                {sections[id].questions[idx].sub_config_message &&
                                                                                                    <BsFillInfoCircleFill color="#0f52b9" style={{ cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title={sections[id].questions[idx].sub_config_message} />

                                                                                                }
                                                                                                &nbsp; {`Question #${idx + 1}:`}
                                                                                            </label>
                                                                                            <div className="col-sm-10">
                                                                                                <input type="text" name={catId} data-id={idx} data-secid={id} data-name="question" id={catId} required value={sections[id].questions[idx].question} disabled={this.disableQuestion(sections[id].questions[idx]) || OutcomesStaticQues.includes(sections[id].questions[idx].question)} className="form-control" />
                                                                                            </div>

                                                                                            {sections[id].questions[idx].for_ml_model !== 'yes' && !OutcomesStaticQues.includes(sections[id].questions[idx].question) && (
                                                                                                <div
                                                                                                    style={{
                                                                                                        position: 'absolute',
                                                                                                        top: '5px',
                                                                                                        right: '25px',
                                                                                                        width: '50px',
                                                                                                        height: '25px'
                                                                                                    }}
                                                                                                    className=""
                                                                                                    data-id={id}
                                                                                                    onClick={this.quesDelete(val.question_id)}
                                                                                                >
                                                                                                    <Link data-id={id} className="btn btn-danger" to="#">
                                                                                                        <i data-id={id} className="fa fa-trash-o fa-lg"></i>{' '}
                                                                                                    </Link>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        {['TEXT', 'NUMBER'].includes(sections[id].questions[idx].answer_type) && ((!sections[id].questions[idx].is_primary_key && !this.state.set_unique_id) || sections[id].questions[idx].is_primary_key) && (
                                                                                            <div className="col-sm-12" style={{ textAlign: 'right' }}>
                                                                                                <input className="form-check-input" data-name="is_primary_key" data-id={idx} data-secid={id} name={is_primary_key} id={is_primary_key} checked={sections[id].questions[idx].is_primary_key} style={{ marginLeft: '0.75rem' }} type="checkbox" />
                                                                                                <label className="form-check-label" style={{ marginLeft: '2rem' }}>
                                                                                                    Set Unique Id
                                                                                                </label>
                                                                                            </div>
                                                                                        )}

                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                top: '5px',
                                                                                                right: '-22px'
                                                                                            }}
                                                                                            onMouseEnter={() => this.handleDragEffect(idx, true)}
                                                                                            onMouseLeave={() => this.handleDragEffect(idx, false)}
                                                                                            data-toggle="tooltip"
                                                                                            data-placement="top"
                                                                                            title="drag"
                                                                                        >
                                                                                            <span {...provided.dragHandleProps} className="btn btn-primary">
                                                                                                <BsArrowsMove style={{ fontSize: '20px' }} />
                                                                                            </span>
                                                                                        </div>

                                                                                        {this.state.show[idx] ? (
                                                                                            <React.Fragment>
                                                                                                {id !== 0 && org_type === 3 && Config != 2 && (
                                                                                                    <React.Fragment>
                                                                                                        <div className="form-group field-row" key={idx}>
                                                                                                            <label className="col-sm-2 col-form-label font-weight-bold " htmlFor={fml_question_map_Id}>
                                                                                                                Map Family Question:
                                                                                                            </label>
                                                                                                            <div className="col-sm-10">
                                                                                                                <DropdownComponent fmlBasicRequirmentList={fmlBasicRequirmentList} handleChange={this.Map_Question_handleChange} name={fml_question_map_Id} sectionId={id} questionId={idx} value={sections[id].questions[idx].fml_question_map} dataName="fml_question_map" />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </React.Fragment>
                                                                                                )}
                                                                                                <div className="form-group field-row" key={idx}>
                                                                                                    <label className="col-sm-2 col-form-label font-weight-bold " htmlFor={descId}>
                                                                                                        Description:
                                                                                                    </label>
                                                                                                    <div className="col-sm-10">
                                                                                                        <input type="text" name={descId} data-id={idx} data-secid={id} data-name="description" id={descId} value={sections[id].questions[idx].description} className="form-control" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                {sections[id].questions[idx].answer_type === 'NUMBER' && sections[id].questions[idx].is_primary_key && (
                                                                                                    <fieldset className="form-group">
                                                                                                        <div className="field-row">
                                                                                                            <legend className="col-form-label col-sm-2 font-weight-bold" htmlFor={fieldTypeId}>
                                                                                                                Field Type:
                                                                                                            </legend>
                                                                                                            <div className="col-sm-10">
                                                                                                                <div className="form-check form-check-inline">
                                                                                                                    <input type="radio" name={fieldTypeId} className="form-check-input" data-id={idx} data-secid={id} data-name="field_type" id={fieldTypeId} checked={sections[id].questions[idx].field_type?.toString() === '0'} value="0" />
                                                                                                                    <label className="form-check-label">Auto</label>
                                                                                                                </div>
                                                                                                                <div className="form-check form-check-inline">
                                                                                                                    <input type="radio" name={fieldTypeId} className="form-check-input" data-id={idx} data-secid={id} data-name="field_type" id={fieldTypeId} checked={sections[id].questions[idx].field_type?.toString() === '1'} value="1" />
                                                                                                                    <label className="form-check-label">Manual</label>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </fieldset>
                                                                                                )}
                                                                                                {((Object.keys(sections[id].questions[idx]).includes('field_type') && sections[id].questions[idx].field_type?.toString() !== '0') || !Object.keys(sections[id].questions[idx]).includes('field_type')) && (
                                                                                                    <React.Fragment>
                                                                                                        <fieldset className="form-group">
                                                                                                            <div className="field-row">
                                                                                                                <React.Fragment>
                                                                                                                    <legend className="col-form-label col-sm-2 font-weight-bold" htmlFor={typeId}>
                                                                                                                        Answer Type:
                                                                                                                    </legend>
                                                                                                                    <div className="col-sm-10">
                                                                                                                        <div className="form-check form-check-inline">
                                                                                                                            <input
                                                                                                                                className="form-check-input"
                                                                                                                                name={typeId}
                                                                                                                                data-id={idx}
                                                                                                                                data-secid={id}
                                                                                                                                data-name="answer_type"
                                                                                                                                id={typeId}
                                                                                                                                type="radio"
                                                                                                                                disabled={sections[id].questions[idx].question === 'Referral Status' ? true : false || (sections[id].questions[idx].id && this.state.is_historic_data_available)}
                                                                                                                                checked={sections[id].questions[idx].answer_type === 'SELECT'}
                                                                                                                                //disabled={this.state.answer_type !== "SELECT" ? true : false}
                                                                                                                                value="SELECT"
                                                                                                                                required
                                                                                                                            />
                                                                                                                            <label className="form-check-label">Drop Down</label>
                                                                                                                        </div>
                                                                                                                        <div className="form-check form-check-inline">
                                                                                                                            <input className="form-check-input" name={typeId} data-id={idx} data-secid={id} data-name="answer_type" id={typeId} type="radio" value="TEXT" disabled={sections[id].questions[idx].question === 'Referral Status' ? true : false || (sections[id].questions[idx].id && this.state.is_historic_data_available)} checked={sections[id].questions[idx].answer_type === 'TEXT'} required />
                                                                                                                            <label className="form-check-label">Text</label>
                                                                                                                        </div>
                                                                                                                        <div className="form-check form-check-inline">
                                                                                                                            <input className="form-check-input" name={typeId} data-id={idx} data-secid={id} data-name="answer_type" id={typeId} type="radio" value="NUMBER" disabled={sections[id].questions[idx].question === 'Referral Status' ? true : false || (sections[id].questions[idx].id && this.state.is_historic_data_available)} checked={sections[id].questions[idx].answer_type === 'NUMBER'} required />
                                                                                                                            <label className="form-check-label">Number</label>
                                                                                                                        </div>
                                                                                                                        <div className="form-check form-check-inline">
                                                                                                                            <input className="form-check-input" name={typeId} data-id={idx} data-secid={id} data-name="answer_type" id={typeId} type="radio" value="CHECKBOX" disabled={sections[id].questions[idx].question === 'Referral Status' ? true : false || (sections[id].questions[idx].id && this.state.is_historic_data_available)} checked={sections[id].questions[idx].answer_type === 'CHECKBOX'} required />
                                                                                                                            <label className="form-check-label">Checkbox</label>
                                                                                                                        </div>
                                                                                                                        <div className="form-check form-check-inline">
                                                                                                                            <input className="form-check-input" name={typeId} data-id={idx} data-secid={id} data-name="answer_type" id={typeId} type="radio" value="RADIO" checked={sections[id].questions[idx].answer_type === 'RADIO'} required disabled={sections[id].questions[idx].id && this.state.is_historic_data_available} />
                                                                                                                            <label className="form-check-label">Radio</label>
                                                                                                                        </div>
                                                                                                                        <div className="form-check form-check-inline">
                                                                                                                            <input className="form-check-input" name={typeId} data-id={idx} data-secid={id} data-name="answer_type" id={typeId} type="radio" value="FILE" disabled={sections[id].questions[idx].question === 'Referral Status' ? true : false || (sections[id].questions[idx].id && this.state.is_historic_data_available)} checked={sections[id].questions[idx].answer_type === 'FILE'} required />
                                                                                                                            <label className="form-check-label">Upload File</label>
                                                                                                                        </div>
                                                                                                                        <div className="form-check form-check-inline">
                                                                                                                            <input className="form-check-input" name={typeId} data-id={idx} data-secid={id} data-name="answer_type" id={typeId} type="radio" value="DATE" disabled={sections[id].questions[idx].question === 'Referral Status' ? true : false || (sections[id].questions[idx].id && this.state.is_historic_data_available)} checked={sections[id].questions[idx].answer_type === 'DATE'} required />
                                                                                                                            <label className="form-check-label">Date</label>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </React.Fragment>
                                                                                                            </div>
                                                                                                        </fieldset>

                                                                                                        {['text', 'number', 'related', 'date', 'file']?.includes(this.state.sections[id].questions[idx].answer_type?.toLowerCase()) || this.state.sections[id].questions[idx].answer_type === '' ? (
                                                                                                            ''
                                                                                                        ) : (
                                                                                                            <Fragment>
                                                                                                                <fieldset className="form-group">
                                                                                                                    <div className="field-row">
                                                                                                                        <legend className="col-form-label col-sm-2 font-weight-bold" htmlFor={add_type_id}>
                                                                                                                            Add Type:
                                                                                                                        </legend>
                                                                                                                        <div className="col-sm-10">
                                                                                                                            <div className="form-check form-check-inline">
                                                                                                                                <input type="radio" name={add_type_id} className="form-check-input" data-id={idx} data-secid={id} data-name="add_type" id={add_type_id} checked={['normal', ''].includes(sections[id].questions[idx].add_type)} data-secname={sections[id] && sections[id].questions[idx].question} value="normal" />
                                                                                                                                <label className="form-check-label">Normal</label>
                                                                                                                            </div>
                                                                                                                            <div className="form-check form-check-inline">
                                                                                                                                <input type="radio" name={add_type_id} className="form-check-input" data-id={idx} data-secid={id} data-name="add_type" id={add_type_id} checked={sections[id].questions[idx].add_type === 'upload'} data-secname={sections[id] && sections[id].questions[idx].question} value="upload" />
                                                                                                                                <label className="form-check-label">Bulk Upload</label>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </fieldset>

                                                                                                                {sections[id].questions[idx].add_type === 'upload' && (
                                                                                                                    <div className="download-box">
                                                                                                                        <UploadOrDownloadBulkList id={id} idx={idx} appendUploadedAnswers={this.appendUploadedAnswers} MapAnswerList={this.MapAnswerList(null)} sections={this.state.sections} />
                                                                                                                    </div>
                                                                                                                )}
                                                                                                                <fieldset className="form-group">
                                                                                                                    <div className="field-row">
                                                                                                                        <legend className="col-form-label col-sm-2 font-weight-bold" htmlFor={is_parent_Id}>
                                                                                                                            Sort:
                                                                                                                        </legend>
                                                                                                                        <div className="col-sm-10">
                                                                                                                            <div className="form-check form-check-inline">
                                                                                                                                <BsSortAlphaUp className="ascending-icon" onClick={() => this.sortHandler(id, idx, 'asc')} />
                                                                                                                            </div>
                                                                                                                            <div className="form-check form-check-inline">
                                                                                                                                <BsSortAlphaDown className="decending-icon" onClick={() => this.sortHandler(id, idx, 'desc')} />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </fieldset>
                                                                                                                {sections[id].questions[idx].is_ans_dep_on_prev_que_ans === 'yes' && ['SELECT', 'RADIO'].includes(sections[id].questions[idx].answer_type) && <UploadDependentAnswers {...this.props} sections={sections} sectionIndex={id} questionIndex={idx} downloadTemplate={GetDependentAnswersTemplate} uploadTemplate={UploadConfiguredDependentAnswers} downloadConfigure={DownloadConfiguredDependentAnswers} updateState={this.updateState} />}

                                                                                                                <div className={this.state.sections[id].questions[idx]?.suggested_answers.filter((item) => !item.is_deleted)?.length === 1 ? 'dropdown-single-answer' : 'dropdown-answers-box'}>
                                                                                                                    {this.state.sections[id].questions
                                                                                                                        .filter((p) => p.question_id === this.state.sections[id].questions[idx].question_id)
                                                                                                                        .map((q, answerIndex) => {
                                                                                                                            let suggested_id = 0;
                                                                                                                            return q.suggested_answers.map((question, idy) => {
                                                                                                                                suggested_id = question.is_deleted ? suggested_id : suggested_id + 1;
                                                                                                                                let setTypeID = `set_type-${idx}${idy}`;
                                                                                                                                return (
                                                                                                                                    <React.Fragment>
                                                                                                                                        <div className={['form-group field-row', question.is_deleted ? 'hideElement' : ''].join(' ')}>
                                                                                                                                            <label className="col-sm-2 col-form-label font-weight-bold">Answer {suggested_id}:</label>
                                                                                                                                            <div className="col-sm-3">
                                                                                                                                                {question.hasOwnProperty('mappingList') ? (
                                                                                                                                                    <Fragment>
                                                                                                                                                        <Select key={id} value={{ value: question.value, label: question.value }} name="child_questions" components={animatedComponents} options={this.ReferralStatusOptions(question?.mappingList)} className="basic-multi-select" placeholder="Select answer" onChange={this.Referral_Status_HandleChange(id, idx, idy, question)} />
                                                                                                                                                        {sections[id].questions[idx].suggested_answers.length !== ReferralStatusMappedList.length && suggested_id === this.state.sections[id].questions[idx].suggested_answers.filter((item) => item.is_deleted === false).length && (
                                                                                                                                                            <div
                                                                                                                                                                style={{
                                                                                                                                                                    position: 'absolute',
                                                                                                                                                                    top: '1px',
                                                                                                                                                                    right: '-25px',
                                                                                                                                                                    width: '25px',
                                                                                                                                                                    height: '25px',
                                                                                                                                                                    fontSize: '24px'
                                                                                                                                                                }}
                                                                                                                                                                className="font-text-bold text-center "
                                                                                                                                                                data-answer={q.answer_type}
                                                                                                                                                                data-secid={id}
                                                                                                                                                                data-id={idy}
                                                                                                                                                                onClick={() => this.addReferralAnswer(id, idx)}
                                                                                                                                                            >
                                                                                                                                                                <i
                                                                                                                                                                    className="fa fa-plus"
                                                                                                                                                                    style={{
                                                                                                                                                                        fontSize: '28px'
                                                                                                                                                                    }}
                                                                                                                                                                    data-answer={q.answer_type}
                                                                                                                                                                    data-secid={id}
                                                                                                                                                                    data-id={idy}
                                                                                                                                                                ></i>
                                                                                                                                                            </div>
                                                                                                                                                        )}
                                                                                                                                                    </Fragment>
                                                                                                                                                ) : (
                                                                                                                                                    <React.Fragment>
                                                                                                                                                        <input type="text" name={answerId} data-id={idx} data-secid={id} data-name="suggested_answers" id={answerId} data-idy={idy} value={question.value ? question.value : ''} className="form-control" required />
                                                                                                                                                        {!(suggested_id === 1 && suggested_id === this.state.sections[id].questions[idx].suggested_answers.filter((item) => item.is_deleted === false).length) && (
                                                                                                                                                            <div
                                                                                                                                                                style={{
                                                                                                                                                                    position: 'absolute',
                                                                                                                                                                    top: '1px',
                                                                                                                                                                    right: '-0px',
                                                                                                                                                                    width: '25px',
                                                                                                                                                                    height: '25px',
                                                                                                                                                                    fontSize: '24px',
                                                                                                                                                                    color: 'red'
                                                                                                                                                                }}
                                                                                                                                                                className="font-text-bold text-center "
                                                                                                                                                                data-secid={id}
                                                                                                                                                                data-id={idy}
                                                                                                                                                                onClick={this.handleDelete(idx, idy, question)}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-remove" data-secid={id} data-id={idy}></i>
                                                                                                                                                            </div>
                                                                                                                                                        )}

                                                                                                                                                        {suggested_id === this.state.sections[id].questions[idx].suggested_answers.filter((item) => item.is_deleted === false).length && (
                                                                                                                                                            <div
                                                                                                                                                                style={{
                                                                                                                                                                    position: 'absolute',
                                                                                                                                                                    top: '1px',
                                                                                                                                                                    right: '-25px',
                                                                                                                                                                    width: '25px',
                                                                                                                                                                    height: '25px',
                                                                                                                                                                    fontSize: '24px'
                                                                                                                                                                }}
                                                                                                                                                                className="font-text-bold text-center "
                                                                                                                                                                data-answer={q.answer_type}
                                                                                                                                                                data-secid={id}
                                                                                                                                                                data-id={idy}
                                                                                                                                                                onClick={this.addAnswer(idx, idy)}
                                                                                                                                                            >
                                                                                                                                                                <i
                                                                                                                                                                    className="fa fa-plus"
                                                                                                                                                                    style={{
                                                                                                                                                                        fontSize: '28px'
                                                                                                                                                                    }}
                                                                                                                                                                    data-answer={q.answer_type}
                                                                                                                                                                    data-secid={id}
                                                                                                                                                                    data-id={idy}
                                                                                                                                                                ></i>
                                                                                                                                                            </div>
                                                                                                                                                        )}
                                                                                                                                                    </React.Fragment>
                                                                                                                                                )}
                                                                                                                                            </div>

                                                                                                                                            {this.state.sections[id].questions[idx].answer_type === 'CHECKBOX' ? (
                                                                                                                                                <div className="col-sm-4 mx-4 px-3 py-2">
                                                                                                                                                    <div className="form-check form-check-inline">
                                                                                                                                                        <input type="radio" name={setTypeID} className="form-check-input" data-secid={id} data-id={idx} data-idy={idy} data-name="setType" id={setTypeID} checked={sections[id].questions[idx].suggested_answers[idy].setType === 'setA'} value="setA" required />
                                                                                                                                                        <label className="form-check-label">Set-A</label>
                                                                                                                                                    </div>
                                                                                                                                                    <div className="form-check form-check-inline">
                                                                                                                                                        <input type="radio" name={setTypeID} className="form-check-input" data-secid={id} data-id={idx} data-idy={idy} data-name="setType" id={setTypeID} checked={sections[id].questions[idx].suggested_answers[idy].setType === 'setB'} value="setB" required />
                                                                                                                                                        <label className="form-check-label">Set-B</label>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            ) : this.state.sections[id].questions[idx].is_parent === 'yes' ? (
                                                                                                                                                <>
                                                                                                                                                    <label className="col-sm-1 col-form-label font-weight-bold ml-3">Link Child Questions:</label>
                                                                                                                                                    <div className="col-sm-3">
                                                                                                                                                        <Select
                                                                                                                                                            key={id}
                                                                                                                                                            value={this.ChildQuestionOptions(id, idx).filter((value) => this.state.sections[id].questions[idx].child_questions[idy] && Array.isArray(this.state.sections[id].questions[idx].child_questions[idy].questions) && this.state.sections[id].questions[idx].child_questions[idy].questions.includes(value.value))}
                                                                                                                                                            isClearable
                                                                                                                                                            styles={colourStyles}
                                                                                                                                                            isMulti
                                                                                                                                                            name="child_questions"
                                                                                                                                                            components={animatedComponents}
                                                                                                                                                            options={this.ChildQuestionOptions(id, idx)}
                                                                                                                                                            className="basic-multi-select"
                                                                                                                                                            placeholder="Select child Questions"
                                                                                                                                                            onChange={this.Child_Question_handleChange(id, idx, idy)}
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                </>
                                                                                                                                            ) : (
                                                                                                                                                <>
                                                                                                                                                    <label className="col-sm-1 col-form-label font-weight-bold ml-3">Jump to: </label>
                                                                                                                                                    <div className="col-sm-3">
                                                                                                                                                        <Select key={id} value={this.jumpOptions(id, idx, idy).filter((value) => this.state.sections[id].questions[idx].suggested_jump[idy] && Array.isArray(this.state.sections[id].questions[idx].suggested_jump[idy].jumpto) && this.state.sections[id].questions[idx].suggested_jump[idy].jumpto.includes(value.value))} isClearable styles={colourStyles} isMulti name="suggested_jump" components={animatedComponents} options={this.jumpOptions(id, idx, idy)} className="basic-multi-select" placeholder="Select Sections jump" onChange={this.multihandleChange(id, idx, idy)} />
                                                                                                                                                    </div>

                                                                                                                                                    <div className="col-sm-3">
                                                                                                                                                        <Select
                                                                                                                                                            value={this.Question_jumpOptions(id, idx, idy).filter((value) => this.state.sections[id].questions[idx].suggested_jump[idy] && Array.isArray(this.state.sections[id].questions[idx].suggested_jump[idy].question_jumpto) && this.state.sections[id].questions[idx].suggested_jump[idy].question_jumpto.includes(value.value))}
                                                                                                                                                            isClearable
                                                                                                                                                            styles={colourStyles}
                                                                                                                                                            isMulti
                                                                                                                                                            name="ques_suggested_jump"
                                                                                                                                                            options={this.Question_jumpOptions(id, idx, idy)}
                                                                                                                                                            className="basic-multi-select"
                                                                                                                                                            placeholder="Select jump within Section"
                                                                                                                                                            onChange={this.Question_multihandleChange(id, idx, idy)}
                                                                                                                                                            components={animatedComponents}
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                </>
                                                                                                                                            )}
                                                                                                                                        </div>
                                                                                                                                    </React.Fragment>
                                                                                                                                );
                                                                                                                            });
                                                                                                                        })}
                                                                                                                </div>
                                                                                                            </Fragment>
                                                                                                        )}
                                                                                                        {this.state.sections[id].questions[idx].answer_type === 'TEXT' ? (
                                                                                                            <React.Fragment>
                                                                                                                <div className="" style={{ display: 'flex', justifyContent: 'flex-start', margin: '5px 5px' }} key={idx}>
                                                                                                                    <label className="col-sm-2 col-form-label font-weight-bold " htmlFor={validationId1}>
                                                                                                                        Validation:
                                                                                                                    </label>

                                                                                                                    <div className="" style={{ margin: '5px' }}>
                                                                                                                        <select name={validationId2} data-id={idx} data-secid={id} data-name="validation2" id={validationId2} className="form-control" onChange={this.handleChange} required>
                                                                                                                            <option key="0" value="">
                                                                                                                                Select
                                                                                                                            </option>
                                                                                                                            <option key="1" value="Alphabet" selected={sections[id].questions[idx].validation2 === 'Alphabet'}>
                                                                                                                                Alphabet
                                                                                                                            </option>
                                                                                                                            <option key="2" value="Numbers" selected={sections[id].questions[idx].validation2 === 'Numbers'}>
                                                                                                                                Alphabet + Numbers
                                                                                                                            </option>
                                                                                                                            <option key="3" value="Special characters" selected={sections[id].questions[idx].validation2 === 'Special characters'}>
                                                                                                                                Alphabet + Special characters
                                                                                                                            </option>
                                                                                                                            <option key="4" value="Both" selected={sections[id].questions[idx].validation2 === 'Both'}>
                                                                                                                                All
                                                                                                                            </option>
                                                                                                                        </select>
                                                                                                                        <div className="" style={{ textAlign: 'right' }}>
                                                                                                                            {sections[id].questions[idx].validation2 !== '' && (
                                                                                                                                <React.Fragment>
                                                                                                                                    <input className="form-check-input" name={exact_match_id} style={{ marginLeft: '0.75rem' }} checked={sections[id].questions[idx].exact_match} type="checkbox" value={sections[id].questions[idx].exact_match} onChange={this.handleChange} data-name="exact_match" data-id={idx} data-secid={id} />
                                                                                                                                    <label className="form-check-label" style={{ marginLeft: '2rem' }}>
                                                                                                                                        Exact Match
                                                                                                                                    </label>
                                                                                                                                </React.Fragment>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    {!['Alphabet', 'Numbers', ''].includes(sections[id].questions[idx].validation2) && (
                                                                                                                        <React.Fragment>
                                                                                                                            {!['Numbers', ''].includes(sections[id].questions[idx].validation2) && (
                                                                                                                                <div className="" style={{ margin: '5px' }}>
                                                                                                                                    <input type="text" name={splCharactersID} data-id={idx} data-secid={id} data-name="spl_characters" id={splCharactersID} placeholder="Special Characters" value={sections[id].questions[idx].spl_characters} className="form-control" />
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </React.Fragment>
                                                                                                                    )}
                                                                                                                    {sections[id].questions[idx].validation2 !== '' && (
                                                                                                                        <div className="" style={{ margin: '5px', width: '30%' }}>
                                                                                                                            <input type="text" name={errorId} data-id={idx} data-secid={id} data-name="error_msg" id={errorId} placeholder="Error message" value={sections[id].questions[idx].error_msg} className="form-control" />
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                        {this.state.sections[id].questions[idx].answer_type === 'NUMBER' ? (
                                                                                                            <React.Fragment>
                                                                                                                <div className="form-group field-row" key={idx}>
                                                                                                                    <label className="col-sm-2 col-form-label font-weight-bold " htmlFor={validationId1}>
                                                                                                                        Validation:
                                                                                                                    </label>
                                                                                                                    <label className="col-sm-1 col-form-label font-weight-bold ">Between</label>
                                                                                                                    <div className="col-sm-2">
                                                                                                                        <input type="text" name={validationId1} data-id={idx} data-secid={id} data-name="validation1" id={validationId1} placeholder="Number" value={sections[id].questions[idx].validation1} className="form-control" style={{ border: `${this.state.sections[id].questions[idx].validation1 === '' ? '2px solid #FF0000' : ''}` }} />
                                                                                                                        {this.state.sections[id].questions[idx].validation1 === '' ? <span className="text-danger fs-6">Required!</span> : ''}
                                                                                                                    </div>
                                                                                                                    <span className="text-center"> and</span>

                                                                                                                    <div className="col-sm-2">
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            name={validationId2}
                                                                                                                            data-id={idx}
                                                                                                                            data-secid={id}
                                                                                                                            data-name="validation2"
                                                                                                                            id={validationId2}
                                                                                                                            placeholder="Number"
                                                                                                                            value={sections[id].questions[idx].validation2}
                                                                                                                            className="form-control"
                                                                                                                            style={{
                                                                                                                                border: `${this.state.sections[id].questions[idx].validation1 !== '' && this.state.sections[id].questions[idx].validation2 === '' ? '2px solid #FF0000' : ''}`
                                                                                                                            }}
                                                                                                                        />
                                                                                                                        {this.state.sections[id].questions[idx].validation1 !== '' && this.state.sections[id].questions[idx].validation2 === '' ? <span className="text-danger fs-6">Required!</span> : ''}
                                                                                                                    </div>
                                                                                                                    <div className="col-sm-4">
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            name={errorId}
                                                                                                                            data-id={idx}
                                                                                                                            data-secid={id}
                                                                                                                            data-name="error_msg"
                                                                                                                            id={errorId}
                                                                                                                            placeholder="Error message"
                                                                                                                            value={sections[id].questions[idx].error_msg}
                                                                                                                            className="form-control"
                                                                                                                            style={{
                                                                                                                                border: `${this.state.sections[id].questions[idx].validation1 !== '' && this.state.sections[id].questions[idx].error_msg === '' ? '2px solid #FF0000' : ''}`
                                                                                                                            }}
                                                                                                                        />
                                                                                                                        {this.state.sections[id].questions[idx].validation1 !== '' && this.state.sections[id].questions[idx].error_msg === '' ? <span className="text-danger fs-6">Required!</span> : ''}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                        {['TEXT'].includes(this.state.sections[id].questions[idx].answer_type) ? (
                                                                                                            <React.Fragment>
                                                                                                                <div className="" style={{ display: 'flex', justifyContent: 'flex-start', margin: '5px 5px' }} key={idx}>
                                                                                                                    <label className="col-sm-2 col-form-label font-weight-bold " htmlFor={validationId1}>
                                                                                                                        Length:
                                                                                                                    </label>

                                                                                                                    <div className="" style={{ margin: '5px' }}>
                                                                                                                        <input
                                                                                                                            type="number"
                                                                                                                            name={validationId3}
                                                                                                                            data-id={idx}
                                                                                                                            data-secid={id}
                                                                                                                            data-name="validation3"
                                                                                                                            id={validationId3}
                                                                                                                            placeholder="Minimum characters"
                                                                                                                            value={sections[id].questions[idx].validation3}
                                                                                                                            className="form-control"
                                                                                                                            style={{
                                                                                                                                border: `${this.state.sections[id].questions[idx].validation3 !== '' && this.state.sections[id].questions[idx].validation3 === '' ? '2px solid #FF0000' : ''}`
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div className="" style={{ margin: '5px' }}>
                                                                                                                        <input
                                                                                                                            type="number"
                                                                                                                            name={validationId4}
                                                                                                                            data-id={idx}
                                                                                                                            data-secid={id}
                                                                                                                            data-name="validation4"
                                                                                                                            id={validationId3}
                                                                                                                            placeholder="Maximum characters"
                                                                                                                            value={sections[id].questions[idx].validation4}
                                                                                                                            className="form-control"
                                                                                                                            style={{
                                                                                                                                border: `${this.state.sections[id].questions[idx].validation4 !== '' && this.state.sections[id].questions[idx].validation4 === '' ? '2px solid #FF0000' : ''}`
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>

                                                                                                                    <div className="" style={{ margin: '5px', width: '30%' }}>
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            name={error_msg2}
                                                                                                                            data-id={idx}
                                                                                                                            data-secid={id}
                                                                                                                            data-name="error_msg2"
                                                                                                                            id={error_msg2}
                                                                                                                            placeholder="Error message"
                                                                                                                            value={sections[id].questions[idx].error_msg2}
                                                                                                                            className="form-control"
                                                                                                                            style={{
                                                                                                                                border: `${(this.state.sections[id].questions[idx].validation3 || this.state.sections[id].questions[idx].validation4) && this.state.sections[id].questions[idx].error_msg2 === '' ? '2px solid #FF0000' : ''}`
                                                                                                                            }}
                                                                                                                        />
                                                                                                                        {(this.state.sections[id].questions[idx].validation3 || this.state.sections[id].questions[idx].validation4) && this.state.sections[id].questions[idx].error_msg2 === '' ? <span className="text-danger fs-6">Required!</span> : ''}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                    </React.Fragment>
                                                                                                )}
                                                                                                {sections[id].questions[idx].is_for_hiding !== 'yes' && this.state.sections[id].questions[idx].for_ml_model === 'yes' && this.state.sections[id].section === 'Outcomes' && (
                                                                                                    <React.Fragment>
                                                                                                        <div key={`ml_model_path${idx}`} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '5px 5px' }}>
                                                                                                            <label className="col-sm-4 col-form-label font-weight-bold " htmlFor={ml_model_path}>
                                                                                                                Upload ML Model :
                                                                                                            </label>{' '}
                                                                                                            <div key={`ml_model_path${idx}`} style={{ margin: '5px' }}>
                                                                                                                <Dropzone onDrop={(e) => this.onUploadModelFiles(e, id, idx, 'ml_model_path', 'pkl')} accept="pkl/*" isMulti={false}>
                                                                                                                    {({ getRootProps, getInputProps }) => (
                                                                                                                        <div {...getRootProps()} className="dropzone">
                                                                                                                            <input {...getInputProps()} data-details={idx} />
                                                                                                                            <div className="btn btn-primary font-weight-bold btn-block report_button">
                                                                                                                                <BsUpload /> &nbsp;
                                                                                                                                <label className="form-check-label">Upload</label>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </Dropzone>
                                                                                                            </div>
                                                                                                            {val.ml_model_path !== '' ? val?.ml_model_path?.substring(val?.ml_model_path.lastIndexOf('/') + 1) : ''}
                                                                                                        </div>

                                                                                                        <div key={`ml_input_fields_path${idx}`} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '5px 5px' }}>
                                                                                                            <label className="col-sm-4 col-form-label font-weight-bold " htmlFor={ml_input_fields_path}>
                                                                                                                Upload ML Fields :
                                                                                                            </label>{' '}
                                                                                                            <div key={`ml_input_fields_path${idx}`} style={{ margin: '5px' }}>
                                                                                                                <Dropzone onDrop={(e) => this.onUploadModelFiles(e, id, idx, 'ml_input_fields_path', 'json')} accept="json/*" isMulti={false}>
                                                                                                                    {({ getRootProps, getInputProps }) => (
                                                                                                                        <div {...getRootProps()} className="dropzone">
                                                                                                                            <input {...getInputProps()} />
                                                                                                                            <div className="btn btn-primary font-weight-bold btn-block report_button">
                                                                                                                                <BsUpload /> &nbsp;
                                                                                                                                <label className="form-check-label">Upload</label>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </Dropzone>
                                                                                                            </div>
                                                                                                            {val.ml_input_fields_path !== '' ? val?.ml_input_fields_path?.substring(val?.ml_input_fields_path.lastIndexOf('/') + 1) : ''}
                                                                                                        </div>
                                                                                                    </React.Fragment>
                                                                                                )}

                                                                                                <fieldset className="form-group">
                                                                                                    <div className="field-row m-2">
                                                                                                        <legend className="col-form-label col-sm-2 font-weight-bold" htmlFor={is_child_Id}>
                                                                                                            Add Flags:
                                                                                                        </legend>
                                                                                                        <div className="col-sm-10">
                                                                                                            <MultipleFlagSelect is_configuration_available={is_configuration_available} flagOptions={flagList} sections={sections} sectionId={id} questionId={idx} data={sections[id].questions[idx]} handleChange={this.MultipleFlagSelectHandler} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </fieldset>
                                                                                            </React.Fragment>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </Draggable>
                                                                </div>
                                                            );
                                                        })}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                        <div className=" col d-flex justify-content-end">
                                            <button className="btn btn-primary" data-id={id} data-idx={this.state.sections[id].questions.length - 1} onClick={this.addQuestion}>
                                                {this.state.sections[id].questions.length === 0 ? 'Add Question' : 'Add Next Question'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            ) : (
                                ''
                            )}
                            <button onClick={this.handleNavToTop} type="button" className="btn btn-primary rounded-pill mb-1" id="btn-back-to-top">
                                <BsFillArrowUpCircleFill />
                            </button>
                            <button onClick={this.handleNavToBottom} type="button" className="btn btn-primary rounded-pill" id="btn-back-to-bottom">
                                <BsFillArrowDownCircleFill />
                            </button>
                            {!this.state.isLoading && (
                                <div className="pb-1">
                                    <div className="footer row mt-3 text-center fixed-bottom bg-white p-1 pt-2">
                                        {/* <div className="row mt-3 text-center"> */}
                                        <div className="col col-sm-2  mb-1">
                                            <button className="btn btn-primary" disabled={id === 0 ? true : sectionLength >= 1 ? false : true} onClick={this.onPreviuos}>
                                                Previous Section
                                            </button>
                                        </div>
                                        <div className="col col-sm-2 mb-1">
                                            <button className="btn btn-primary" disabled={sectionLength === id ? true : false} onClick={this.onNext}>
                                                Next Section
                                            </button>
                                        </div>
                                        <div className="col col-sm-2">
                                            <button className="btn btn-primary" onClick={this.addSection} disabled={sectionLength === id ? false : true}>
                                                Add Section
                                            </button>
                                        </div>
                                        <div className="col col-sm-2">
                                            <button onClick={this.handlePreview} className="btn btn-primary" disabled={sectionLength > -1 ? false : true}>
                                                Preview
                                            </button>
                                        </div>
                                        <div className="col col-sm-2">
                                            <button onClick={this.handleSubmit} className="btn btn-primary" disabled={sectionLength > -1 ? false : true}>
                                                Submit
                                            </button>
                                        </div>
                                        <div className="col col-sm-2">
                                            <button onClick={this.onLast} className="btn btn-primary" disabled={sectionLength === id ? true : false}>
                                                Last Section
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Fragment>
            );
        }
    }
}
const mapStateToProps = (state) => {
    return {
        organizationsList: state.getorganization.organizationsList
    };
};
export default connect(mapStateToProps)(QuestionConfigure);
